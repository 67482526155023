import React from "react";

const Step1 = props => {
  if (props.currentStep !== 1) {
    return null;
  }
  /*const changeCategories= useCallback(
    () => {
    {props.categories.filter(category => category.parent === obj.value).map((e, key) => {
      var option = document.createElement("option");
      option.text = e.title;
      option.value = e.slug;
      var select = document.getElementById("kategori");
      select.appendChild(option);
    })}
  })*/
 
  
  return (
    <>
      <p>Lütfen kategori seçin</p>
      <div>
        <form>
          <div className="row">
            <div className="col-md-3">
              <div>Ana kategori: </div>
              <select className="form-select form-control" size="13" placeholder="Ana Kategori" value={props.anakategori} name="anakategori" id="anakategori" onChange={props.handleMainCategoryChange}>
                {props.categories && props.categories.filter(category => category.parent === 0).map((e, key) => {
                  return <option key={key} value={e.id}>{e.title}</option>;
                })}
              </select>
            </div>
            <div className="col-md-3">
              <div>Kategori: </div>
              <select className="form-select form-control" size="13" placeholder="Kategori" value={props.kategori} name="kategori" id="kategori" onChange={props.handleCategoryChange}>
                {props.categories && props.categories.filter(category => category.parent == props.anakategori).map((e, key) => {
                              return <option key={key} value={e.id}>{e.title}</option>;
                            })}
              </select>
            </div>
            <div className="col-md-3">
              <div>Alt Kategori: </div>
              <select className="form-select form-control" size="13" placeholder="Alt Kategori" value={props.altkategori} name="altkategori" id="altkategori" onChange={props.handleSubCategoryChange}>
              {props.categories && props.categories.filter(category => category.parent == props.kategori).map((e, key) => {
                              return <option key={key} value={e.id}>{e.title}</option>;
                            })}
              </select>
            </div>
            <div className="col-md-3">
              {props.showBrands &&
              <><div>Marka: </div>
              <div className="mb-1"><input type="text" className="form-control" value={props.selectedBrandText} onChange={props.handleFilterChange} /></div>
              <select className="form-select form-control" size="10" placeholder="Marka" value={props.selectedBrand} name="marka" id="marka" onChange={props.handleBrandChange}>
                {props.brands && props.brands.map((e, key) => {
                  return <option key={key} value={e.title}>{e.title}</option>;
                })}
              </select>
              </>
              }
            </div>
          </div>
          {props.showBrands && 
          <>
          <div className="d-flex m-0 p-0">
          <div className="form-group col-4 m-0 p-1 text-left">
          <label htmlFor="model">Model</label>
          <input className="form-control"
            type="text"
            name="model"
            id="model"
            placeholder="Ürün modelini girin"
            defaultValue={props.model}
            onChange={props.handleChange}
          /></div>
          
          <div className="form-group col-4 m-0 p-1 text-left">
          <label htmlFor="seri">Seri</label>
            <input className="form-control"
            type="text"
            name="seri"
            id="seri"
            placeholder="Ürün serisini girin"
            defaultValue={props.seri}
            onChange={props.handleChange}
          /></div>
        
          </div>
          </>}
        </form>
      </div>
    </>
  );
};

export default Step1;
