import React, {Component} from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Video from '../parts/video';
import Swal from "sweetalert2";

              /*<div className="form-check pb-2 text-left text-muted">
                <input type="checkbox" id="reg_remember" name="user_remember" className="form-check-input" value="1"/>
                <label className="form-check-label" for="reg_remember">Beni Hatırla</label>
              </div>*/
export class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      isLoading: false,
      token: '',
      username: '',
      passwd:''
    }
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    var retype = 'success';
    const { cookies } = this.props;
    //const url = new URL(document.referrer)
    this.setState({ isLoading: true });
    fetch(this.props.apiurl + '/api/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        
        body: JSON.stringify({
          "username": this.state.username,
          "password": this.state.passwd,
        }),
        credentials: 'include'
      })
      .then((response) => {
        if(!response.ok) {
          retype = 'error';
          return response.json();
        }
        else{
          retype = 'success';
          return response.json();
        } 
      })
      .then((responseData) => {
         cookies.set('access-token', responseData.token, { path: '/' });
         Swal.fire({icon: retype,
         text: responseData.message,
         timer: 5000,
         showConfirmButton: false,
         showCancelButton: false,
         showCloseButton: true,
         closeButtonCaption: 'Kapat'
       });
         if (retype==='success') {
                window.location=document.referrer;
          }
        })

      .catch((error) => {
        this.setState({errorDesc: error});
      });
      
      this.setState({ isLoading: false });

  }
  

    render() {
        document.title="Mezatvar - Giriş Yap"
        return (
          <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl} />
          <Header q={this.props.q} slug={this.props.slug} apiurl={this.props.apiurl}/>
            <div className="container mb-4 mt-4 pt-3">

              <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card-group mb-0">
                  <div className="card p-4">
                  <form onSubmit={this.handleSubmit}>
                    <div className="card-body">
                      <h2>Giriş Yap</h2>
                      <p className="text-muted">Hesabınıza Giriş Yapın</p>
                      <div className="input-group mb-3 prepend" >
                        <span className="input-group-text"><i className="fa fa-user"></i></span>
                        <input type="text" className="form-control" placeholder="Kullanıcı Adı veya Eposta" name="username" onChange={this.handleChange} required/>
                      </div>
                      <div className="input-group mb-3">
                        <span className="input-group-text"><i className="fa fa-lock"></i></span>
                        <input type="password" className="form-control" placeholder="Şifre" name="passwd" onChange={this.handleChange} required/>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <a href="/sifremiunuttum" className="btn btn-link px-0">Şifremi unuttum</a>
                        </div>
                        <div className="col-6 text-right">
                          <button type="submit" className="btn btn-blue px-4 float-right">Giriş Yap</button>
                        </div>

                      </div>
                      
                    </div>
                    </form>
                  </div>
                  <div className="card text-white btn-green pt-4 pb-4 d-md-down-none">
                    <div className="card-body text-center">
                      <div>
                        <h2>Henüz Üye Değil Misiniz?</h2>
                        <p className="mb-4 mt-4">Üyelerimize özel hizmetlerimizden faydalanabilmek için üye olun.</p>
                        <br/><a href="/kayitol" className="btn btn-dark mt-3">Üye Ol</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
            </div>

            </div>
            
            <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
            <FooterSection apiurl={this.props.apiurl}/>
        </div>
        )
    }
}

export default withCookies(LoginPage);