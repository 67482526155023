import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import '../assets/css/markalar.css';
import Video from '../parts/video';

export class Brands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      isLoading: false,
      isOpen: false,
      jsonData: [],
      firstLetters: ['a', 'b', 'c', 'ç', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l',
      'm', 'n', 'o', 'ö', 'p', 'q', 'r', 's', 't', 'u', 'ü', 'v', 'w', 'x', 'y', 'z']
    }
    this.getBrands = this.getBrands.bind(this);
  }

  getBrands() {
    this.setState({ isLoading: true });
    fetch(this.props.apiurl + '/api/brands', {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then((data) => {
          this.setState({ jsonData: data, isLoading: false })
      })
      .catch(err => {
        this.setState({errorDesc: err});
        //this.myToast("Teknik bir problemimiz var.<br/> Mezat detayı alınamadı oluşturulamadı.<br/> Problem detayı:" + err);
      })
  }

  componentDidMount() {
    this.getBrands();
   }

  render() {
    document.title = "Mezatvar - Tüm Markalar"
    return (
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl} />
        <Header q={this.props.q} slug={this.props.slug} apiurl={this.props.apiurl} />

        <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded">
        <div className="row mb-2"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
        <h6>Tüm Markalar</h6>
        <div className="row">
        <div className="col-md-4 col-sm-12">
            <div className="card marka">
              <div className="p-0 m-0 text-dark font-weight-bold">
                <span><h6>#</h6></span>
              </div>
              <div className="liste">
        {this.state.jsonData && this.state.jsonData.filter((brand => brand.title.match(/^\d/))).map((brandWithNumber, bnindex) => {
            return (
                <div className="listeitem text-center p-1" id={'div' + bnindex} key={bnindex}>
                  <a id={bnindex} className="btn btn-sm btn-blue w-75" href={'marka/' + brandWithNumber.slug}>{brandWithNumber.title}</a>
              </div>               
              )
        })

        } </div>
        </div>
        </div>
        {this.state.firstLetters && this.state.firstLetters.map((firstLetter, findex) => {
          var parents = this.state.jsonData.filter(brand => brand.title.toLowerCase().startsWith(firstLetter));
          
          return  <div className="col-md-4 col-sm-12">
                <div className="card marka">
                  <div className="p-0 m-0 text-dark font-weight-bold">
                    <span><h6>{firstLetter.toUpperCase()}</h6></span>
                  </div>
                  <div className="liste">
                  {parents.map((brand, bindex) =>{
                    return <div className="text-center p-1" id={'div' + bindex} key={bindex}>
                      <a id={bindex} className="btn btn-sm btn-blue w-75" href={'marka/' + brand.slug}>{brand.title}</a>
                  </div>
                  })                  
                  }
                  </div>
                </div>
              </div>
        }        )}
        </div>
        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl} />
      </div>
    )
  }
}

export default Brands;