//import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';

export class DateCombo extends Component {
  state = {
    Months: [
      {
        id:1, monthName: 'Ocak', days: 31
      },
      {
        id:2, monthName: 'Şubat', days: 29
      },
      {
        id:3, monthName: 'Mart', days: 31
      },
      {
        id:4, monthName: 'Nisan', days: 30
      },
      {
        id:5, monthName: 'Mayıs', days: 31
      },
      {
        id:6, monthName: 'Haziran', days: 30
      },
      {
        id:7, monthName: 'Temmuz', days: 31
      },
      {
        id:8, monthName: 'Ağustos', days: 31
      }, 
      {
        id:9, monthName: 'Eylül', days: 30
      },
      {
        id:10, monthName: 'Ekim', days: 31
      },
      {
        id:11, monthName: 'Kasım', days: 30
      },
      {
        id:12, monthName: 'Aralık', days: 31
      }
    ],
    StartYear: 1930,
    EndYear: 2004,
    SelectedDay: this.props.selectedDay ? this.props.selectedDay: 1,
    SelectedMonth: this.props.selectedMonth ? this.props.selectedMonth: 1,
    SelectedYear: this.props.selectedYear ? this.props.selectedYear: 2004,
  }

  /*componentDidMount() {

  }*/


  render() {
    var years=[];
    for(var i=this.state.StartYear;i<=this.state.EndYear;i++){
        years.push(<option value={i} selected={this.state.SelectedYear === i}>{i}</option>);
    }
    var months=[];
    this.state.Months && this.state.Months.map((item) => months.push(<option value={item.id} selected={this.state.SelectedMonth === item.id}>{item.monthName}</option>))
  
    var days=[];
    for(var d=1;d<=31;d++){
      days.push(<option value={d} selected={this.state.SelectedDay === d}>{d}</option>);
    }

  

    return (
      <div className="d-flex justify-content-left">
        <div className="form-group">
          <select className="form-control form-control-sm" id="gun" name="gun" onChange={this.props.handleChange}>
            {days}
          </select>
        </div>
        <div className="form-group">
          <select className="form-control form-control-sm" id="ay" name="ay" onChange={this.props.handleChange}>
          {months}        
          </select>
        </div>
        <div className="form-group">
          <select className="form-control form-control-sm" id="yil" name="yil" onChange={this.props.handleChange}>
          {years}
          </select>
        </div>
      </div>
    )
  }
}
export default DateCombo;