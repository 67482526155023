import React, { Component } from 'react';
import './stepper.css';

class Stepper extends Component {
 
    render() {
        return (
            <div className="stepper-wrapper">
                {this.props.steps && this.props.steps.map((step, index) => (
                
                <div className={index+1<this.props.activestep ? "stepper-item completed": index+1===this.props.activestep ? "stepper-item active" : "stepper-item"}>
                    <div className="step-counter">{index+1}</div>
                    <div className="step-name">{step}</div>
                </div>
                ))}
                
            </div>

        )
    }
}

export default Stepper;