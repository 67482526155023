import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import '../assets/css/markalar.css';
import Video from '../parts/video';

export class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      isLoading: false,
      isOpen: false,
      jsonData: [],
    }
    this.getCategories = this.getCategories.bind(this);
  }

  getCategories() {
    this.setState({ isLoading: true });
    fetch(this.props.apiurl + '/api/categories', {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then((data) => {
          this.setState({ jsonData: data, isLoading: false })
      })
      .catch(err => {
        this.setState({errorDesc: err});
        //this.myToast("Teknik bir problemimiz var.<br/> Mezat detayı alınamadı oluşturulamadı.<br/> Problem detayı:" + err);
      })
  }

  componentDidMount() {
    this.getCategories();
   }

  render() {
    document.title = "Mezatvar - Tüm Kategoriler"
    var parents = this.state.jsonData.filter(category => category.parent === 0);
    return (
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl} />
        <Header q={this.props.q} slug={this.props.slug} apiurl={this.props.apiurl} />

        <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded">
        <div className="row mb-2"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
        <h6>Tüm Kategoriler</h6>
        <div className="row">
        
        {parents && parents.map((mainCategory, findex) => {
         
          return  <div className="col-md-4 col-sm-12">
                <div className="card marka">
                  <div className="p-0 m-0 text-dark font-weight-bold">
                    <span><h6><a href={'kategori/' + mainCategory.slug}>{mainCategory.title}</a></h6></span>
                  </div>
                  <div className="liste">
                  {this.state.jsonData.filter(subcategory => subcategory.parent == mainCategory.id).map((category1, bindex) =>{
                    return <div className="listeitem text-center p-1" id={'div' + bindex} key={bindex}>
                      <a id={bindex} className="btn btn-sm btn-blue w-75" href={'kategori/' + category1.slug}>{category1.title}</a>
                  </div>
                  })                  
                  }
                  </div>
                </div>
              </div>
        }        )}
        </div>
        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl} />
      </div>
    )
  }
}

export default Categories;