import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import swal from 'sweetalert2';

export class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    //const id = new URLSearchParams(search).get('id');

    this.state = {
      message: '',
    }
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  componentDidMount() {
    const expires = this.getParameterByName('expires');
    const signature = this.getParameterByName('signature');
    const { cookies } = this.props;
    var retype = 'success';

    fetch(this.props.apiurl + '/api/email/verify/' + this.props.id + '/' + this.props.hash + '?expires=' + expires + '&signature=' + signature, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookies.get('access-token'),
      },
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('Çıkış yapmanız için üye girişi yapmış olmalısınız')
          }
          else {
            throw new Error(response.status + ':' + response.statusText)
          }
        }
        else {
          return response.json();
        }
      })
      .then((responseData) => {
        swal.fire({
          icon: retype,
          text:  responseData.message,
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      })

      .catch((error) => {
        swal.fire({
          icon: 'error',
          text:  'Bir hata oluştu. ' + error,
          timer: 7000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      });
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  render() {

    document.title = "Mezatvar - Eposta Doğrulama"
    return (
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
        <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
        <div className="container mb-4 mt-4 pt-3">
          <div className="page-404">
            <div className="outer">
              <div className="middle">
                <div className="inner">
                  <span className="inner-status card">{this.state.authVerifyUrl} </span>
                  <span className="inner-detail">
                    <a href="/u/profilim" className="btn btn-info mtl m-3"><i className="fa fa-home"></i>&nbsp;
                      Profil Sayfanıza gidin
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl}/>
      </div>

    )
  }
}

export default withCookies(VerifyEmail);