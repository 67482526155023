import React, {Component} from "react";
import ModalForm from '../modal/modalform';

import Step1 from "./salesformstep1";
import Step2 from "./salesformstep2";
import Step3 from "./salesformstep3";
import Step4 from "./salesformstep4";

import MultiStepProgressBar from "../multistepprogressbar/multistepprogressbar";
import {Card} from 'react-bootstrap';
import {withCookies, Cookies} from "react-cookie";
import {instanceOf} from "prop-types";

import sanitizeHtml from 'sanitize-html';
import Swal from "sweetalert2";


class SalesFormMaster extends Component {
    constructor(props) {
        super(props);

        // Set the intiial input values

        // Bind the submission to handleChange()
        this.handleChange = this.handleChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleMainCategoryChange = this.handleMainCategoryChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleSubCategoryChange = this.handleSubCategoryChange.bind(this);
        this.handleBrandChange = this.handleBrandChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);

        this.fileHandleChange = this.fileHandleChange.bind(this);
        this.handleEditableContent = this.handleEditableContent.bind(this);
        this.checkForm = this.checkForm.bind(this);
        // Bind new functions for next and previous
        this._next = this._next.bind(this);
        this._prev = this._prev.bind(this);
    }

    state = {
        isOpen: false,
        activeModal: null,
        currentStep: 1,
        anakategori: "",
        kategori: "",
        altkategori: "",
        marka: "",
        brands: this.props.brands,
        filteredBrands: this.props.brands,
        selectedBrandText: "",
        model: "",
        seri: "",
        description: "",
        urunismi: "",
        openingprice: 0,
        buynowprice: "",
        shipping_payment_by: 1,
        startdate: new Date(),
        enddate: this.addHours(24),
        files: [],
        shipping_type: 1,
        show_brand: 1,
        property_1: '',
        property_2: '',
        property_3: '',
        property_4: '',
        property_5: '',
        property_6: '',
        property_7: '',
        property_8: '',
        property_9: '',
        property_10: '',
        property_11: '',
        property_12: '',
        property_13: '',
        property_14: '',
        property_15: '',
    };

    addHours(numOfHours, date = new Date()) {
        date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
        return date;
    }

    openModal = () => this.setState({isOpen: true});
    closeModal = () => this.setState({isOpen: false, activeModal: null});

    clickHandler(e, index) {
        e.preventDefault();
        if (this.state.files.length < 4) {
            Swal.fire({
                icon: 'warning',
                text: 'Ürüne ait en az 4 adet fotoğraf yüklemeniz gerekmektedir',
                timer: 5000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
            });
        } else {
            this.setState({isOpen: true, activeModal: index})
        }
    }

    checkForm() {

        switch (this.state.currentStep) {
            case 1:
                if (this.state.anakategori == "") {
                    return ('Bir ana kategori seçmeniz zorunludur');
                }
                if (this.state.kategori == "") {
                    return ('Bir kategori seçmeniz zorunludur');
                }
                if (this.state.altkategori == "") {
                    return ('Bir alt kategori seçmeniz zorunludur');
                }
                if (this.state.marka == "") {
                    return ('Bir marka seçmeniz zorunludur');
                }
                return true;

            case 2:
                // console.log("This is step 2 beginning");
                // var bulundu = false;

                var returnData = "";

                this.props.categories && this.props.categories.filter(
                    cat => cat.id == this.state.altkategori ||
                        cat.id == this.state.kategori ||
                        cat.id == this.state.anakategori)
                    .sort().reverse().map(altcat => {

                        // if (bulundu == false && altcat.property_group) {
                        // return this.props.propGroups && this.props.propGroups.filter(p => p.id == altcat.property_group).map((e, key) => {
                        //     bulundu = true;


                        if (altcat.property_group && this.props.propGroups) {
                            var thegroup = this.props.propGroups.filter(
                                p => p.id == altcat.property_group);

                            // console.log(this.props.propGroups);
                            // console.log("thegroup: ");
                            // console.log(thegroup);

                            // console.log("1: " + thegroup[0].label_1_values + " " + thegroup[0].label_1_title);
                            // console.log("15: " + thegroup[0].label_15_values + " " + thegroup[0].label_15_values);

                            if (thegroup[0].label_15_values &&
                                (this.state.property_15 === null ||
                                    this.state.property_15 === "")) {
                                // console.log("e.label_15_values");
                                // return (thegroup.label_15_title + ' seçmeniz zorunludur 15');
                                returnData = thegroup[0].label_15_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_14_values &&
                                (this.state.property_14 === null ||
                                    this.state.property_14 === "")) {
                                returnData = thegroup[0].label_14_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_13_values &&
                                (this.state.property_13 === null ||
                                    this.state.property_13 === "")) {
                                returnData = thegroup[0].label_13_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_12_values &&
                                (this.state.property_12 === null ||
                                    this.state.property_12 === "")) {
                                returnData = thegroup[0].label_12_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_11_values &&
                                (this.state.property_11 === null ||
                                    this.state.property_11 === "")) {
                                returnData = thegroup[0].label_11_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_10_values &&
                                (this.state.property_10 === null ||
                                    this.state.property_10 === "")) {
                                returnData = thegroup[0].label_10_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_9_values &&
                                (this.state.property_9 === null ||
                                    this.state.property_9 === "")) {
                                returnData = thegroup[0].label_9_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_8_values &&
                                (this.state.property_8 === null ||
                                    this.state.property_8 === "")) {
                                returnData = thegroup[0].label_8_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_7_values &&
                                (this.state.property_7 === null ||
                                    this.state.property_7 === "")) {
                                returnData = thegroup[0].label_7_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_6_values &&
                                (this.state.property_6 === null ||
                                    this.state.property_6 === "")) {
                                returnData = thegroup[0].label_6_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_5_values &&
                                (this.state.property_5 === null ||
                                    this.state.property_5 === "")) {
                                returnData = thegroup[0].label_5_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_4_values &&
                                (this.state.property_4 === null ||
                                    this.state.property_4 === "")) {
                                returnData = thegroup[0].label_4_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_3_values &&
                                (this.state.property_3 === null ||
                                    this.state.property_3 === "")) {
                                returnData = thegroup[0].label_3_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_2_values &&
                                (this.state.property_2 === null ||
                                    this.state.property_2 === "")) {

                                // console.log("property_2: " + this.state.property_2);

                                returnData = thegroup[0].label_2_title + ' seçmeniz zorunludur';
                            }
                            if (thegroup[0].label_1_values &&
                                (this.state.property_1 === null ||
                                    this.state.property_1 === "")) {
                                // console.log("e.label_1_values");
                                // return (thegroup.label_1_title + ' seçmeniz zorunludur 1');
                                // console.log("property_1: " + this.state.property_1);

                                returnData = thegroup[0].label_1_title + ' seçmeniz zorunludur';
                            }
                            if (this.state.description === null ||
                                this.state.description === "") {
                                returnData = 'Ürün açıklamasını girmeniz zorunludur.';
                            }
                            if (this.state.urunismi === null ||
                                this.state.urunismi === "") {
                                returnData = 'Ürün başlığını girmeniz zorunludur.';
                            }

                        }
                    })

                // console.log("returnData: " + returnData + " |");

                // console.log("This is return true step 2");
                // return true;
                if (returnData === "" || returnData === null) {
                    return true;
                } else {
                    return returnData;
                }

            case 3:
                console.log("This is step 3");
                return true;

            default:
                console.log("This is step default");
                return true;
        }

    }

// Use the submitted data to set the state
    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }


    handleFilterChange(event) {
        this.setState({selectedBrandText: event.target.value})
        let varfilteredBrands = this.state.brands.filter(item => item.title.toString().toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
        this.setState({filteredBrands: varfilteredBrands});
    }

    handleEditableContent(e) {
        this.setState({description: sanitizeHtml(e.currentTarget.innerHTML)})
    }

    handleStartDateChange(date) {
        this.setState({
            startdate: date
        })
    }

    handleEndDateChange(date) {
        this.setState({
            enddate: date
        })
    }

    removeOptions(selectElement) {
        var i, L = selectElement.options.length - 1;
        for (i = L; i >= 0; i--) {
            selectElement.remove(i);
        }
    }

    addOptions(selectElement, id, value) {
        var option = document.createElement("option");
        option.value = id;
        option.text = value;
        selectElement.appendChild(option);
    }

    handleMainCategoryChange(event) {
        const {value} = event.target;
        this.setState({
            anakategori: value, kategori: 65535,
        });
        this.props.categories.filter(category => category.parent == value).slice(0, 1).map((e, key) => {
            this.setState({kategori: e.id})
            this.props.categories.filter(category => category.parent == e.id).slice(0, 1).map((v, key) => {
                this.setState({altkategori: v.id, show_brand: v.show_brand, shipping_type: v.shipping_type})
            })
        })

    }

    componentDidMount() {
        var anakat = 0;
        var kat = 0;
        var altkat = 0;
        this.props.categories.filter(category => category.parent == 0).slice(0, 1).map((e, key) => {
            this.setState({anakategori: e.id})
            anakat = e.id
        })
        this.props.categories.filter(category => category.parent == anakat).slice(0, 1).map((e, key) => {
            this.setState({kategori: e.id})
            kat = e.id
        })
        this.props.categories.filter(category => category.parent == kat).slice(0, 1).map((e, key) => {
            this.setState({altkategori: e.id})
            altkat = e.id
        })
    }

    handleCategoryChange(event) {
        const {value} = event.target;
        this.setState({
            kategori: value
        });
        this.props.categories.filter(category => category.parent == value).slice(0, 1).map((e, key) => {
            this.setState({altkategori: e.id, show_brand: e.show_brand, shipping_type: e.shipping_type})
        })
    }

    handleSubCategoryChange(event) {
        const {value} = event.target;
        this.setState({
            altkategori: value
        });
        this.props.categories.filter(category => category.id == value).slice(0, 1).map((e, key) => {
            this.setState({show_brand: e.show_brand, shipping_type: e.shipping_type})
        })
    }

    handleBrandChange(event) {
        const {value} = event.target;
        this.setState({
            'marka': value, selectedBrandText: value
        });
    }

    fileHandleChange(e) {
        this.setState({
            "files": e
        });
    }

// Trigger an alert on form submission
    handleSubmit = event => {
        event.preventDefault();
        this.setState({isOpen: false});
        Swal.fire({
            title: 'Kayıt Yapılıyor',
            html: 'Mezat kaydı yapılıyor. Lütfen bekleyin',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        if (this.state.startdate > this.state.enddate) {
            Swal.fire({
                icon: 'warning',
                text: 'Başlangıç tarihi, bitiş tarihinden küçük olamaz',
                timer: 5000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
            });
        } else {
            const {cookies} = this.props;
            var formData = new FormData()
            this.state.files && this.state.files.map((file, index) => {
                formData.append('images[]', file);
            });

            //formData.append('name',  this.state.urunismi)
            formData.append('title', this.state.urunismi)
            formData.append('shipping_payment_by', this.state.shipping_payment_by)
            formData.append('category_1_id', this.state.anakategori)
            formData.append('category_2_id', this.state.kategori)
            formData.append('category_3_id', this.state.altkategori)
            formData.append('brand', this.state.marka)
            formData.append('model', this.state.model)
            formData.append('series', this.state.seri)
            formData.append('description', this.state.description)
            formData.append('buynow_price', this.state.buynowprice)
            formData.append('opening_price', this.state.openingprice)
            formData.append('start_time', this.state.startdate)
            formData.append('end_time', this.state.enddate)
            formData.append('property_1', this.state.property_1)
            formData.append('property_2', this.state.property_2)
            formData.append('property_3', this.state.property_3)
            formData.append('property_4', this.state.property_4)
            formData.append('property_5', this.state.property_5)
            formData.append('property_6', this.state.property_6)
            formData.append('property_7', this.state.property_7)
            formData.append('property_8', this.state.property_8)
            formData.append('property_9', this.state.property_9)
            formData.append('property_10', this.state.property_10)
            formData.append('property_11', this.state.property_11)
            formData.append('property_12', this.state.property_12)
            formData.append('property_13', this.state.property_13)
            formData.append('property_14', this.state.property_14)
            formData.append('property_15', this.state.property_15)

            fetch(this.props.apiurl + '/api/addAuction', {
                method: 'POST', headers: {
                    'Accept': 'application/json', 'Authorization': 'Bearer ' + cookies.get('access-token'),
                }, credentials: 'include', body: formData
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.statusText)
                    } else {
                        return response.json();
                    }
                })
                .then((responseData) => {
                    //cookies.set('access-token', responseData.token, { path: '/' });
                    window.location = '/mezatozeti/' + responseData.slug;
                })

                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Bir hata oluştu. ' + error,
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });

                });
        }
    };


    static
    propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
// Test current step with ternary
// _next and _previous functions will be called on button click
    _next = (e) => {
        e.preventDefault();
        let currentStep = this.state.currentStep;
        let isValid = this.checkForm();
        console.log("isValid: " + isValid + " currentStep: " + currentStep);
        if (isValid === true) {
            // If the current step is 1 or 2, then add one on "next" button click
            currentStep = currentStep >= 3 ? 4 : currentStep + 1;
            this.setState({
                currentStep: currentStep
            });
        } else {
            Swal.fire({
                icon: 'warning',
                text: isValid,
                timer: 5000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
            });
        }
    }

    _prev = (e) => {
        e.preventDefault();
        let currentStep = this.state.currentStep;

        // If the current step is 2 or 3, then subtract one on "previous" button click
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
            currentStep: currentStep
        });
    }

// The "next" and "previous" button functions
    get previousButton() {
        let currentStep = this.state.currentStep;

        // If the current step is not 1, then render the "previous" button
        if (currentStep !== 1) {
            return (<button className="btn btn-secondary float-left" onClick={this._prev}>
                Önceki
            </button>);
        }

        // ...else return nothing
        return null;
    }

    get nextButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 3, then render the "next" button
        if (currentStep < 4) {
            return (<button className="btn btn-blue float-right" onClick={this._next}>
                Sonraki
            </button>);
        }
        // ...else render nothing
        return null;
    }

    get submitButton() {
        let currentStep = this.state.currentStep;

        // If the current step is the last step, then render the "submit" button
        if (currentStep > 3) {
            return <button className="btn btn-blue float-right"
                           onClick={e => this.clickHandler(e, 0)}>Kaydet</button>;
        }
        // ...else render nothing
        return null;
    }

    render() {
        return (<>
            <form>
                <Card>
                    <Card.Header><h2>Satış Kaydı</h2></Card.Header>
                    <Card.Body>
                        <Card.Title>
                            <MultiStepProgressBar currentStep={this.state.currentStep}/>
                        </Card.Title>
                        <Card.Body/>
                        <Step1
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            handleMainCategoryChange={this.handleMainCategoryChange}
                            handleCategoryChange={this.handleCategoryChange}
                            handleSubCategoryChange={this.handleSubCategoryChange}
                            handleBrandChange={this.handleBrandChange}
                            handleFilterChange={this.handleFilterChange}
                            handleEditableContent={this.handleEditableContent}
                            anakategori={this.state.anakategori}
                            kategori={this.state.kategori}
                            altkategori={this.state.altkategori}
                            marka={this.state.marka}
                            selectedBrandText={this.state.selectedBrandText}
                            model={this.state.model}
                            seri={this.state.seri}
                            categories={this.props.categories}
                            propGroups={this.props.propGroups}
                            brands={this.state.filteredBrands}
                            showBrands={this.state.show_brand}
                            shippingType={this.state.shipping_type}
                            urunismi={this.state.urunismi}
                            shipping_payment_by={this.state.shipping_payment_by}
                            description={this.state.description}
                            openingprice={this.state.openingprice}
                            buynowprice={this.state.buynowprice}
                            startdate={this.state.startdate}
                            enddate={this.state.enddate}
                            files={this.state.files}
                        />
                        <Step2
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            handleEditableContent={this.handleEditableContent}
                            anakategori={this.state.anakategori}
                            kategori={this.state.kategori}
                            altkategori={this.state.altkategori}
                            categories={this.props.categories}
                            marka={this.state.marka}
                            propGroups={this.props.propGroups}
                            selectedBrandText={this.state.selectedBrandText}
                            model={this.state.model}
                            seri={this.state.seri}
                            urunismi={this.state.urunismi}
                            shipping_payment_by={this.state.shipping_payment_by}
                            showBrands={this.state.show_brand}
                            shippingType={this.state.shipping_type}
                            description={this.state.description}
                            openingprice={this.state.openingprice}
                            buynowprice={this.state.buynowprice}
                            startdate={this.state.startdate}
                            enddate={this.state.enddate}
                            files={this.state.files}
                        />
                        <Step3
                            currentStep={this.state.currentStep}
                            anakategori={this.state.anakategori}
                            kategori={this.state.kategori}
                            altkategori={this.state.altkategori}
                            marka={this.state.marka}
                            categories={this.props.categories}
                            selectedBrandText={this.state.selectedBrandText}
                            model={this.state.model}
                            seri={this.state.seri}
                            urunismi={this.state.urunismi}
                            shipping_payment_by={this.state.shipping_payment_by}
                            showBrands={this.state.show_brand}
                            shippingType={this.state.shipping_type}
                            description={this.state.description}
                            openingprice={this.state.openingprice}
                            buynowprice={this.state.buynowprice}
                            startdate={this.state.startdate}
                            enddate={this.state.enddate}
                            handleChange={this.handleChange}
                            handleStartDateChange={this.handleStartDateChange}
                            handleEndDateChange={this.handleEndDateChange}
                            handleEditableContent={this.handleEditableContent}
                            files={this.state.files}
                        />
                        <Step4
                            currentStep={this.state.currentStep}
                            fileHandleChange={this.fileHandleChange}
                            anakategori={this.state.anakategori}
                            kategori={this.state.kategori}
                            altkategori={this.state.altkategori}
                            marka={this.state.marka}
                            categories={this.props.categories}
                            selectedBrandText={this.state.selectedBrandText}
                            model={this.state.model}
                            seri={this.state.seri}
                            urunismi={this.state.urunismi}
                            shipping_payment_by={this.state.shipping_payment_by}
                            showBrands={this.state.show_brand}
                            shippingType={this.state.shipping_type}
                            description={this.state.description}
                            openingprice={this.state.openingprice}
                            buynowprice={this.state.buynowprice}
                            startdate={this.state.startdate}
                            enddate={this.state.enddate}
                            handleChange={this.handleChange}
                            handleEditableContent={this.handleEditableContent}
                            files={this.state.files}
                        />
                    </Card.Body>
                    <Card.Footer>
                        {this.previousButton}
                        {this.nextButton}
                        {this.submitButton}
                    </Card.Footer>
                </Card>
                {this.state.activeModal === 0 && this.state.isOpen ? <ModalForm
                    activeModal={this.activeModal}
                    closeModal={this.closeModal}
                    isOpen={this.state.isOpen}
                    onHide={this.closeModal}
                    handleSubmit={this.handleSubmit}
                    aria-labelledby="contained-modal-title-vcenter"
                    title="Satış Kaydı Onayı"
                    saveButtonCaption="Evet"
                    closeButtonCaption='Hayır'
                >
                    <h5>Mezatı kaydetmek istediğinize emin misiniz?</h5>
                    <h6>Mezat isteğiniz mezat yöneticilerimiz tarafından incelenip, onaylandıktan sonra
                        yayına
                        girecektir</h6>
                </ModalForm> : null}

            </form>
        </>);
    }
}

export default withCookies(SalesFormMaster);
