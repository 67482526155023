import React, {Component} from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import UserLeftMenu from './userleftmenu';
import NotAuthorized from '../notauthorized';
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";

import DateCombo from '../../components/datecombo/datecombo';
import ModalForm from '../../components/modal/modalform';
import UserMobileMenu from './usermobilemenu';
import sanitizeHtml from 'sanitize-html';
import swal from 'sweetalert2';

export class MyOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorDesc: '',
            isLoading: false,
            isAuthenticated: false,
            isOpen: false,
            isOrderOpen: false,
            activeIndex: 0,
            activeModal: null,
            err: false,
            orders: [],
            menuItems: [],
            orderTitle: '',
            orderText: '',
            newOrderOrderItem: "",
            btnOrderItemStatus: true,
        }
        this.handleSubmitCancel = this.handleSubmitCancel.bind(this);
    }


    openModal = () => this.setState({isOpen: true});
    closeModal = () => this.setState({isOpen: false, activeModal: null});
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    formatDate = (dateString) => {
        const options = {hour: '2-digit', minute: '2-digit', second: '2-digit'}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }
    compareDate = (orderdate) => {
        return (new Date(orderdate).toDateString() == new Date().toDateString());
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    clickHandler(e, index, id) {
        e.preventDefault();
        this.setState({isOpen: true, activeModal: index, activeIndex: id})
    }

    componentDidMount() {
        this.setState({isLoading: true, isAuthenticated: false});
        const {cookies} = this.props;

        fetch(this.props.apiurl + '/api/myorders', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include'
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json();
                } else {
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    this.setState({isLoading: false, isAuthenticated: false})
                } else {
                    if (responseData) {
                        this.setState({
                            isLoading: false,
                            isAuthenticated: true,
                            orders: responseData.orders.data,
                            currentPage: responseData.orders.current_page,
                            lastPage: responseData.orders.last_page,
                            menuItems: responseData.support_titles,
                        })
                    }
                }
            })

            .catch((error) => {
                this.setState({errorDesc: error});
            });
    }

    handleActiveIndex(ind) {
        ind !== this.state.activeIndex ? this.setState({isOrderOpen: true}) : this.setState({isOrderOpen: !this.state.isOrderOpen});
        this.setState({activeIndex: ind});
    }

    handleSubmitCancel() {
        this.setState({isOpen: false});
        const {cookies} = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('order_id', this.state.activeIndex);

        fetch(this.props.apiurl + '/api/cancelOrder', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                } else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: 'error',
                        text: 'Sipariş iptali için üye girişi yapmalısınız',
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                    this.setState({isLoading: false, isAuthenticated: false})
                } else {
                    swal.fire({
                        icon: retype,
                        text: responseData.message,
                        timer: 5000,
                        showConfirmButton: true,
                        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Tamam!',
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    }).then(function () {
                        window.location = '/siparisdetay/' + this.state.activeIndex;
                    });
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text: 'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                });
            });
    }

    goToOrderDetails(orderid) {
        window.location = "/siparisdetay/" + orderid;
    }

    goToOrderConfirmation(orderid) {
        var fetchURL = "/api/MezatVar/OrderConfirmationByBuyer";

        console.log("goToOrderConfirmation orderid: " + orderid);

        var state_vars = this.state;
        state_vars.orderid = orderid;

        fetch(fetchURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(state_vars)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);

                if (result.resultstate === 0) {
                    console.log("OrderConfirmationByBuyer Succeeded");

                    swal.fire({
                        icon: "success",
                        text: "Onaylandı",
                        timer: 5000,
                        showConfirmButton: true,
                        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Tamam!',
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });

                } else {
                    console.log("OrderConfirmationByBuyer Error");

                    swal.fire({
                        icon: 'error',
                        text: 'Onaylanmadı',
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                }
            });
    }

    render() {
        document.title = "Mezatvar - Sipariş Takibi"
        if (this.state.isAuthenticated === true) {
            return (
                !this.state.isLoading &&
                <div className="App">
                    <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                    <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>


                    <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">

                        <nav aria-label="breadcrumb" className="main-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Sipariş Takibi</li>
                            </ol>
                        </nav>

                        <div className="row gutters-sm">
                            <div className="col-md-4 d-none d-md-block">
                                <UserLeftMenu activetab={this.props.activetab}/>
                            </div>
                            <div className="col-md-8">
                                <div className="card">
                                    <UserMobileMenu activetab={this.props.activetab}/>
                                    <div className="card-body tab-content">
                                        <div className="tab-pane active" id="profile">
                                            <h6>SİPARİŞ TAKİBİ</h6>
                                            <hr/>
                                            <div id="ProdListContainer01" className="col-md-12 mt-3 mb-3">
                                                <div className="col-md-12">
                                                    {this.state.orders.length == 0 &&
                                                        <div className="justify-content-center text-center">
                                                            <h1><i className="text-danger fas fa-shopping-cart"></i>
                                                            </h1>
                                                            <h2>Herhangi bir sipariş bulunamadı</h2>
                                                        </div>}
                                                    {this.state.orders && this.state.orders.map((order) => {
                                                        return <div className="card mb-1 question">
                                                            <div
                                                                className={order.status != 2 ? "card-header text-primary font-weight-bold" : "card-header text-danger font-weight-bold"}
                                                                id={order.id}
                                                                onClick={() => this.handleActiveIndex(order.id)}>
                                                                <span>{order.order_item && order.order_item.map((orderitem =>
                                                                    <img
                                                                        className="rounded-circle border border-success p-1 m-1"
                                                                        style={{width: '40px', height: '40px'}}
                                                                        src={this.props.apiurl + orderitem.public_url}/>))} {"Sipariş No: " + order.id} - {order.status_text}</span><span
                                                                className="float-right">{this.formatDate(order.created_at)} {(this.state.activeIndex == order.id && this.state.isOrderOpen) ? "-" : "+"}</span>
                                                            </div>
                                                            {(this.state.activeIndex == order.id && this.state.isOrderOpen) &&
                                                                <div id={"description" + order.id}
                                                                     className="collapse show">
                                                                    <div className="card-body">

                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                            </div>
                                                                            {/*<div className="col-md-12 alert alert-primary mb-4"   role="alert">*/}
                                                                            {/*  <div className="col-md-6"><a href={"siparisdetay/" + order.id}> Sipariş Detayı</a></div>*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                        {order.order_item && order.order_item.map((orderitem =>
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-md-1"></div>
                                                                                        <div
                                                                                            className="col-md-11 alert alert-secondary mb-4">
                                                                                            <img
                                                                                                className="rounded-circle border border-success p-1 m-1"
                                                                                                style={{
                                                                                                    width: '40px',
                                                                                                    height: '40px'
                                                                                                }}
                                                                                                src={this.props.apiurl + orderitem.public_url}/>
                                                                                            <a target="_blank"
                                                                                               href={'mezat/' + orderitem.slug}>{orderitem.name}</a>
                                                                                            {this.numberFormat(orderitem.item_total_price_buyer) + ' ₺'} - {this.formatDate(orderitem.created_at)}
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                        ))}
                                                                        <div
                                                                            className="row  d-flex justify-content-center">
                                                                            <button className="btn btn-green mr-5"
                                                                                    onClick={e => this.goToOrderDetails(order.id)}>
                                                                                Sipariş Detayı
                                                                            </button>

                                                                            {order.status != 8 &&
                                                                                <button className="btn btn-green mr-5"
                                                                                        onClick={e => this.goToOrderConfirmation(order.id)}>
                                                                                    Onay
                                                                                </button>
                                                                            }

                                                                            {order.status == 0 && this.compareDate(order.created_at) &&
                                                                                <button
                                                                                    className="btn btn-small btn-danger"
                                                                                    onClick={e => this.clickHandler(e, 3, order.id)}>Siparişi
                                                                                    İptal Et</button>}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            }
                                                        </div>
                                                    })}

                                                </div>

                                            </div>
                                            {this.state.activeModal === 3 && this.state.isOpen ?
                                                <ModalForm
                                                    activeModal={this.state.activeModal}
                                                    closeModal={this.closeModal}
                                                    isOpen={this.state.isOpen}
                                                    onHide={this.closeModal}
                                                    handleSubmit={this.handleSubmitCancel}
                                                    aria-labelledby="contained-modal-title-vcenter"
                                                    title="Sipariş İptali"
                                                    saveButtonCaption="Evet"
                                                    closeButtonCaption='Hayır'
                                                >


                                                    <h6>{"Siparişi iptal etmek istediğinize emin misiniz?"} </h6>
                                                </ModalForm>
                                                :
                                                null
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
                    <FooterSection apiurl={this.props.apiurl}/>
                </div>
            )
        } else {
            return (!this.state.isLoading &&
                <div className="App">
                    <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                    <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
                    <NotAuthorized/>
                    <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
                    <FooterSection apiurl={this.props.apiurl}/>
                </div>
            )
        }

    }

}


export default withCookies(MyOrders);