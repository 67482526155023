import React, { Component } from 'react';

export class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.getCategories = this.getCategories.bind(this);
 }

  componentDidMount(){
    setInterval(this.getCategories, 1000);
  }

  getCategories() {
    this.setState({ isLoading: true });
    fetch(this.props.apiurl + '/api/categories', {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then((data) => {
        window.location=document.referrer;
      })
      .catch(err => {
        this.setState({error: err})
        //this.myToast("Teknik bir problemimiz var.<br/> Mezat detayı alınamadı oluşturulamadı.<br/> Problem detayı:" + err);
      })
  }

  render() {
    document.title = "Mezatvar - Bakım Çalışması"
    return (
      <div className="App">
         <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded">
         <div className="row">
                <a className="mx-auto my-auto" href="/">
                    <picture>
                      <source media="(min-width:650px)" srcSet="./assets/images/logo.png" />
                      <source media="(min-width:320px)" srcSet="./assets/images/logo_sm.png" />
                      <img src="./assets/images/logo.png" className='img-fluid' alt="Mezat Var" />
                    </picture>
                  </a>
         </div>
          <div className="row">
            <div className="col-sm-12 h-100">
              <div className="row m-1 d-flex justify-content-center">
              <h1 className="d-flex justify-content-center text-primary">
                Bakım çalışması ve sevgi katımı</h1>
              <div className="text-left">
                <p>Geçici aksaklık için özür dileriz, ancak sizlere daha iyi hizmet verebilmek için şu anda bir bakım çalışması yürütüyoruz.
                  Bizimle iletişim kurmak için <a href="mailto:admin@mezatvar.com.tr">admin@mezatvar.com.tr</a> e-posta adresine mesajınızı gönderebilir
                  yahut <a href="https://www.facebook.com/mezatvar.com.tr/" target="_blank">Facebook</a>, <a href="https://www.instagram.com/mezatvar.com.tr/" target="_blank">Instagram</a> ya da <a href="https://www.youtube.com/channel/UCjznRlYbrtjSuX_kuQcj8Bg" target="_blank">Youtube</a> üzerinden takip edebilirsiniz. 
                </p>
                <p>Kısa süre içinde çalışmamız bitecektir. Anlayışınız için teşekkür ederiz.</p>
                <p>— Mezatvar Yönetimi</p>
              </div>
            </div>

          </div>

        </div>
        <div>
        <ul className="list-unstyled mb-5">
              <li>
              <h2><a href="tel:0850 840 44 94"><strong>0850 840 44 94</strong></a></h2>
              </li>
              <li>
                <h4><a href="https://api.whatsapp.com/send/?phone=908508404494&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer"><i className='fab fa-whatsapp'></i> WhatsApp Destek</a></h4>
              </li>
              <li>
                  <section className="mb-5 pb-5">
                  
                  <a className="btn btn-outline-dark btn-floating m-1" href="https://www.facebook.com/mezatvar.com.tr/" role="button" target='_blank' rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>


                  <a className="btn btn-outline-dark btn-floating m-1" href="https://www.instagram.com/mezatvar.com.tr/" role="button" target='_blank' rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>


                  <a className="btn btn-outline-dark btn-floating m-1" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCjznRlYbrtjSuX_kuQcj8Bg" role="button" target='_blank'><i className="fab fa-youtube"></i></a>
                  
                  </section>
                </li>
                
              </ul>
        </div>
      </div>
      </div>
    )
  }
}

export default Maintenance;