import React, { Component } from 'react';
import MetaManager from '../components/metamanager/metamanager';
import { Tabs, Tab } from 'react-bootstrap';
import { urltemizle } from '../components/utils';
import '../assets/css/productdetail.css';
import MezatSayaci from '../components/counter/mezatsayaci';
//import Carousel from 'react-bootstrap/Carousel';
import ModalForm from '../components/modal/modalform';
//import Product from '../parts/product';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Slider from "../components/slider/Slider.js";
import Stars from '../components/stars';
import sanitizeHtml from 'sanitize-html';
import swal from 'sweetalert2';
import Stepper from '../components/stepper/stepper.js';


export class PrevAuctionDetailsChild extends Component {
    constructor(props) {
        super(props);

            this.state.pId = this.props.veri.id;
            this.state.pName = this.props.veri.name;
            this.state.pTitle = this.props.veri.title;
            this.state.pSlug = this.props.veri.slug;
            this.state.pDescription = this.props.veri.description;
            this.state.pCat1Title = this.props.veri.category_1_title;
            this.state.pCat1Slug = this.props.veri.category_1_slug;
            this.state.pCat2Title = this.props.veri.category_2_title;
            this.state.pCat2Slug = this.props.veri.category_2_slug;
            this.state.pCat3Title = this.props.veri.category_3_title;
            this.state.pCat3Slug = this.props.veri.category_3_slug;
            this.state.pEndTime = this.props.veri.end_time;
            this.state.pStartTime = this.props.veri.start_time;
            this.state.pStartedAt = this.props.veri.started_at;
            this.state.pClosedAt = this.props.veri.closed_at;
            this.state.pApproved = this.props.veri.approved;
            this.state.pApprovalText  = this.props.veri.approval_status_text;
            this.props.veri.closed_at!==null ? this.state.auctionStatus = 'Tamamlanmış Mezat': this.state.auctionStatus = 'Devam Eden Mezat';
            this.props.veri.opening_price !== null ? this.state.pOpeningPrice = this.props.veri.opening_price : this.state.pOpeningPrice = 0;
            this.props.veri.current_price !== null ? this.state.pCurrentPrice = this.props.veri.current_price : this.state.pCurrentPrice = this.state.pOpeningPrice;
            this.state.pBuyNowPrice = this.props.veri.buynow_price;
            this.state.pImages = this.props.veri.auction_image;
            this.state.pBids = this.props.veri.auction_bid;
            this.state.pSeller = this.props.veri.seller;
            this.state.pSellerRatings = this.props.veri.seller.userrating;
            this.state.pBidCount = this.props.veri.auction_bid.length;
            this.state.pAuctionQuestions = this.props.veri.auction_question;
            this.state.pShippingType = this.props.veri.shipping_type;
            this.state.pShippingPaymentBy = this.props.veri.shipping_payment_by;
            this.state.pCargoPrices = this.props.cargoPrices;
            this.state.isLoaded=true;

        this.handleSubmitBid = this.handleSubmitBid.bind(this);
        this.handleBuyNow = this.handleBuyNow.bind(this);
        this.handleWish = this.handleWish.bind(this);
        this.handleQuestion = this.handleQuestion.bind(this);
   }
    state = {
        userWatchlist: this.props.userWatchlist,
        existsincart: this.props.existsincart,
        isLoaded: false,
        isOpen: false,
        activeModal: null,
        activePay: null,
        customPay: null,
        auctionStatus: 'Devam Eden Mezat',
        statusClassName: 'row bg-dark p-2 text-white shadow-lg',
        pNotification: '',
        pId: '',
        pName: '',
        pTitle: '',
        pSlug: '',
        pDescription: '',
        pCat1Title: '',
        pCat1Slug: '',
        pCat2Title: '',
        pCat2Slug: '',
        pCat3Title: '',
        pCat3Slug: '',
        pStartTime: '',
        pEndTime: '',
        pOpeningPrice: '',
        pCurrentPrice: '',
        pBuyNowPrice: '',
        pSeller: '',
        pImages: [],
        pBids: [],
        pBidCount: 0,
        pStartedAt: '',
        pClosedAt:'',
        pAuctionQuestions: [],
        pSellerRatings: [],
        ogimage: '',
        question:'',
        notificationMessage: '',
        pApproved:0,
        pApprovalText: "",
        pShippingType: 1,
        pShippingPaymentBy: 1,
        pCargoPrices: [],
        pProperty_label_1: '',
        pProperty_label_2: '',
        pProperty_label_3: '',
        pProperty_label_4: '',
        pProperty_label_5: '',
        pProperty_label_6: '',
        pProperty_label_7: '',
        pProperty_label_8: '',
        pProperty_label_9: '',
        pProperty_label_10: '',
        pProperty_label_11: '',
        pProperty_label_12: '',
        pProperty_label_13: '',
        pProperty_label_14: '',
        pProperty_label_15: '',
        pProperty_value_1: '',
        pProperty_value_2: '',
        pProperty_value_3: '',
        pProperty_value_4: '',
        pProperty_value_5: '',
        pProperty_value_6: '',
        pProperty_value_7: '',
        pProperty_value_8: '',
        pProperty_value_9: '',
        pProperty_value_10: '',
        pProperty_value_11: '',
        pProperty_value_12: '',
        pProperty_value_13: '',
        pProperty_value_14: '',
        pProperty_value_15: '',
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    handleSubmit = (name) => {
        this.setState({ isOpen: false });
    }
    formatDate = (dateString) => {
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
        return new Date(dateString).toLocaleDateString(undefined, options)
      }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    handleWish() {
        swal.fire({
            icon: 'error',
            title: 'Başarısız işlem...',
            text: 'Ön izlemede bu fonksiyon çalışmaz. Önizleme sadece mezatınızın nasıl görüneceğini size sunmak içindir',
            timer: 5000,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
            closeButtonCaption: 'Kapat'
            });           
    }
    handleQuestion() {
        swal.fire({
            icon: 'error',
            title: 'Başarısız işlem...',
            text: 'Ön izlemede bu fonksiyon çalışmaz. Önizleme sadece mezatınızın nasıl görüneceğini size sunmak içindir',
            timer: 5000,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
            closeButtonCaption: 'Kapat'
            });           
    }

    handleBuyNow() {
        swal.fire({
            icon: 'error',
            title: 'Başarısız işlem...',
            text: 'Ön izlemede bu fonksiyon çalışmaz. Önizleme sadece mezatınızın nasıl görüneceğini size sunmak içindir',
            timer: 5000,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
            closeButtonCaption: 'Kapat'
            });           
    }

    handleSubmitBid() {
        swal.fire({
            icon: 'error',
            title: 'Başarısız işlem...',
            text: 'Ön izlemede bu fonksiyon çalışmaz. Önizleme sadece mezatınızın nasıl görüneceğini size sunmak içindir',
            timer: 5000,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: true,
            closeButtonCaption: 'Kapat'
            });           
    }

    clickHandler(e, index) {
        e.preventDefault();
        if (e.target.name=='pey') {
            this.setState({ activePay: index })
        } else if (e.target.name=="ozelpey") {
            this.setState({ activePay: this.state.customPay })
        }
        this.setState({ isOpen: true, activeModal: index })
    }

    myChangeHandler = (event) => {
        event.target.value = event.target.value.replace(/\D/g, '');
        this.setState({ activeModal: 1, customPay: event.target.value.replace(/\D/g, '') });
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    componentDidMount() {
        var approval_state = 0;
        var approval_text = "";
        var approval_icon = "success";
        //document.getElementById('product-description').innerHTML = sanitizeHtml(this.state.pDescription);
        this.state.pImages && this.state.pImages.slice(0, 1).map((ogimg, index) => {
            this.setState({ ogimage: this.props.apiurl + ogimg.public_url });
        })
        switch (this.state.pApproved){
            case (0):
                approval_text = "Mezatınız, mezatvar yönetimi tarafından onaylandıktan sonra yayına aşağıdaki şekilde girecektir";
                approval_icon = "success";
            break;
            case (1):
                approval_text = "Mezat ekleme isteğiniz, mezatvar yönetimi tarafından reddedildi. Açıklama: " + this.state.pApprovalText;
                approval_icon = "error";
            break;
            case (2):
                approval_text = "Mezatınız, mezatvar yönetimi tarafından onaylandı";
                approval_icon = "success";
            break;

        }
        if (this.state.isLoaded) {
            swal.fire({
                icon: approval_icon,
                text: approval_text,
                timer: 7000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
              });    
        }
        
    }
    setUpdate(text, key) {
        const nextItem = this.state.items.map((item) => {
            if (item.key === key) {
                item.text = text;
            }
            return item;
        });
        this.setState({ items: nextItem });
    }
        
    render() {
        return (

            <div className="container">

                <MetaManager title={"Mezatvar - " + this.state.pName} description={this.state.pDescription}
                    sitename={"mezatvar.com.tr"} og_image={this.state.ogimage}
                    og_url={window.location} og_imageAlt={this.state.pName} />
                
                <div className="row p-2 shadow-lg" id="product-detail-status">
                    <div className="col-md-12">
                        <Stepper steps={["Kategori", "Ürün Bilgileri", "Fiyat Bilgileri", "Fotoğraflar", "Özet"]} activestep={5} />
                    </div> 
                </div>
                <div className="text-left pt-2">
                    <div className="small">
                        <ul className="breadcrumb">
                            <li><a href="/">Anasayfa</a></li>
                            <li><a href={"kategori/" + this.state.pCat1Slug}>{this.state.pCat1Title}</a></li>
                            <li><a href={"kategori/" + this.state.pCat1Slug + "/" + this.state.pCat2Slug}>{this.state.pCat2Title}</a></li>
                            <li><a href={"kategori/" + this.state.pCat1Slug + "/" + this.state.pCat2Slug + "/" + this.state.pCat3Slug}>{this.state.pCat3Title}</a></li>
                            <li>{this.state.pName}  </li>
                        </ul>
                    </div>
                </div>
                <div className="productdet-content product-wrap product-deatil shadow-lg">

                    <div className="row">
                    {this.state.existsincart? <div className="col-md-12 col-sm-12 col-xs-12 m-0 text-left normaltext"><div className="alert alert-warning" role="alert">Ürün sepetinizde<a href="/sepetim" className="btn btn-sm btn-green float-right">Sepetinize gitmek için tıklayın</a></div></div>: null}
                        <div className="col-md-6 col-sm-12 col-xs-12 m-0">
                            {this.state.pImages.length > 0 && <Slider imageList={this.state.pImages} containerClassName="container-slider rounded-lg" withThumbs={true} withDots={true} apiurl={this.props.apiurl} zoom={true}  withLinks={false}/>}
                        </div>

                        <div className="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
                        
                            <h5 className="name text-left">{this.state.pTitle}</h5>
                            
                            <h6 className="name text-left">
                                <hr />
                                {this.state.pName}
                                <hr />
                                <p className="small">Mezat No: <span className="float-right">{this.state.pId}</span></p>
                                <p className="small">Başlangıç: <span className="float-right">{this.state.pStartTime && this.formatDate(this.state.pStartTime) + ' GMT+3'}</span></p>
                                <p className="small">Bitiş: <span className="float-right">{this.state.pEndTime && this.formatDate(this.state.pEndTime) + ' GMT+3'}</span></p>
                                <hr />
                                <div className="text-left">
                                    <p>Açılış Fiyatı: {(this.state.pOpeningPrice !== '0' && this.state.pOpeningPrice !== null) ? this.numberFormat(this.state.pOpeningPrice) + ' ₺' : 'Yok'} </p>
                                    <p>Mevcut Fiyat: 
                                    
                                    <span className='hugetext text-success'>                                        
                                        {(this.state.pCurrentPrice !== '0' && this.state.pCurrentPrice !== null) ? <span id='currentprice'> {this.numberFormat(this.state.pCurrentPrice)} ₺ (<a href="#" name="teklifler" onClick={e => this.clickHandler(e, -2)}>{this.state.pBidCount} adet teklif</a>) </span>: 'Teklif Yok'}
                                    </span>
                                    </p>
                                </div>
                                {this.state.pShippingType==1 &&
                                <div className="card p-2">
                                    <p className=""><i className="fa fa-truck"></i> {this.state.pShippingPaymentBy==1 ? "Alıcı Ödemeli Kargo" : "Kargo Bedava"}</p>
                                    {this.state.pCargoPrices && this.state.pCargoPrices.map((cargo_p) => {
                                            return <p className="small"> {cargo_p.cargo_channel_title + ' Kargo ile ' + this.numberFormat(cargo_p.cargo_total_price) + ' ₺ '} </p>
                                    })}
                                </div>}
                                <hr />
                                <div className="row text-left col-md-12 h-100 pb-2">
                                        Pey ver:
                                </div>
                                <div className="row">
                                    <div className="pl-3 col-md-12 text-left h-100">
                                        <div className="row">
                                            <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.state.pBuyNowPrice / 100) > 1 ? parseInt(this.state.pBuyNowPrice / 100) : 1)} className="btn btn-outline-success font-weight-bold m-1 col-md-2">{parseInt(this.state.pBuyNowPrice / 100) > 1 ? parseInt(this.state.pBuyNowPrice / 100) : 1} ₺</button>
                                            <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.state.pBuyNowPrice / 50) > 1 ? parseInt(this.state.pBuyNowPrice / 50) : 5)} className="btn btn-outline-success font-weight-bold m-1 col-md-3">{parseInt(this.state.pBuyNowPrice) / 50 > 1 ? parseInt(this.state.pBuyNowPrice / 50) : 5} ₺</button>
                                            <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.state.pBuyNowPrice / 25) > 1 ? parseInt(this.state.pBuyNowPrice / 25) : 10)} className="btn btn-outline-success font-weight-bold m-1 col-md-3">{parseInt(this.state.pBuyNowPrice) / 25 > 1 ? parseInt(this.state.pBuyNowPrice / 25) : 10} ₺</button>
                                            <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.state.pBuyNowPrice / 10) > 1 ? parseInt(this.state.pBuyNowPrice / 10) : 20)} className="btn btn-outline-success font-weight-bold m-1 col-md-3">{parseInt(this.state.pBuyNowPrice) / 10 > 1 ? parseInt(this.state.pBuyNowPrice / 10) : 20} ₺</button>
                                        </div>
                                        <div className="row">
                                            <form name="ozelpey" onSubmit={e => this.clickHandler(e, 1)} className="row pl-3">
                                            <input type="number" min="0" id="custompay" onChange={this.myChangeHandler} className="form-control m-1 col-md-7" placeholder="Özel Pey Miktarı" required />
                                            <button  type="submit" className="btn btn-blue m-1 col-md-4">Pey Ver</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="pl-3">
                                        {this.state.pBuyNowPrice>0 ? <button name="hemenal" onClick={e => this.clickHandler(e, -1)} className="btn btn-blue">Hemen Al ({this.numberFormat(this.state.pBuyNowPrice)} ₺)</button> : <span></span>}
                                    </div>
                                    <div className="pl-3">
                                        <button onClick={e => this.handleWish(e, -3)} className={this.state.userWatchlist ? "btn btn-danger" :  "btn btn-outline-danger"} title={this.state.userWatchlist ? "Takipten Çık" :  "Takip Et"}><i className="fas fa-heart"></i> {this.state.userWatchlist ? "Takipten Çık" :  "Takip Et"}</button>
                                    </div>
                                </div>

                                <hr />
                                <small>Satıcı: <a href={urltemizle("/profil/" + this.state.pSeller.slug)}>{this.state.pSeller.title}</a>
                                {(this.state.pClosedAt==null && this.state.pStartedAt!=null)  &&     <button name="saticiyasor" onClick={e => this.clickHandler(e, 0)} className="ml-5 btn btn-white btn-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg> Satıcıya Sor 
                                </button>}</small>                                
                                {this.state.pSellerRatings.length>0 && <React.Fragment><Stars ratinggoster={true} rating={(this.state.pSellerRatings.reduce((a,v) =>  a = a + v.rating , 0 ))/this.state.pSellerRatings.length}/><span className="pl-2 fa fa-2x"><h5>{this.state.pSellerRatings.length} değerlendirme</h5></span></React.Fragment>}                               
                                {this.state.activeModal === 0 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleQuestion}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Satıcıya Sor"
                                        saveButtonCaption="Gönder"
                                        closeButtonCaption='İptal'
                                    >
                                        <p><strong>Satıcı:</strong> {this.state.pSeller.title}</p>
                                        <p><strong>Ürün:</strong> {this.state.pName}</p>
                                        <p><textarea name="question" placeholder="Sorunuzu buraya yazın" className="form-control col-md-12" onChange={this.handleChange} required/></p>
                                    </ModalForm>
                                    :
                                    null
                                }

                                {this.state.activeModal > 0 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleSubmitBid}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Pey Ver"
                                        saveButtonCaption="Evet"
                                        closeButtonCaption='Hayır'
                                    >


                                        <h6>{parseInt(this.state.activePay) + " TL pey vermek istediğinize emin misiniz?"} </h6>
                                        <h6>Vereceğiniz pey sonucu teklifiniz <strong>{parseInt(this.state.pCurrentPrice) + parseInt(this.state.activePay)} TL</strong> olacaktır</h6>
                                        {this.state.pShippingType==1 && <React.Fragment>

                                        <p className="mt-4">{this.state.pShippingPaymentBy==1 ? "Not: Kargo ücreti alıcıya aittir" : "Not: Kargo Bedava"}</p>
                                        </React.Fragment>}
                                    </ModalForm>
                                    :
                                    null
                                }

                                {this.state.activeModal === -1 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleBuyNow}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Hemen Al"
                                        saveButtonCaption="Evet"
                                        closeButtonCaption='Hayır'
                                    >
                                        <h4>Ürünü <span className="text-success">{this.numberFormat(this.state.pBuyNowPrice)} TL</span> karşılığında hemen almak istediğinize emin misiniz?</h4>
                                        <p className="smalltext">Önemli uyarı: Mezat sonlanana kadar ödeme yapmadığınız takdirde, ürün sepetinizden silinecek ve mezatı kazanan üyemizin sepetine eklenecektir</p>

                                    </ModalForm>
                                    :
                                    null
                                }
                                {this.state.activeModal === -2 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleBuyNow}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Bu mezata verilen teklifler"
                                        saveButtonCaption=""
                                        closeButtonCaption='Kapat'
                                    >

                                        <table className="table thead-dark table-striped">
                                            <thead><tr><th>Sıra</th><th>Üye</th><th>Pey</th><th>Teklif</th><th>Teklif Zamanı</th></tr></thead>
                                            <tbody>
                                            {this.state.pBids && this.state.pBids.map((mbid, index) => {
                                            return <tr><td>{index+1}</td><td>{mbid.user_title}</td><td>{this.numberFormat(mbid.bid_amount) + ' ₺'}</td><td>{this.numberFormat(mbid.total_amount) + ' ₺'}</td><td>{this.formatDate(mbid.created_at)}</td></tr>
                                        })}</tbody>
                                        </table>
                                    </ModalForm>
                                    :
                                    null
                                }
                                {this.state.activeModal === -3 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleBuyNow}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Takip Listesi"
                                        saveButtonCaption="Evet"
                                        closeButtonCaption='Hayır'
                                    >
                                        <p>Mezatı takibe almak istediğinize emin misiniz?</p>

                                    </ModalForm>
                                    :
                                    null
                                }


                            </h6>
                            <hr />

                        </div>
                        <div className='col-md-12 text-left small'>


                            <Tabs defaultActiveKey="1">
                                
                                {this.state.pDescription && <Tab eventKey="1" title="Ürün Açıklaması">
                                    <div id="product-description" className="tab-item-wrapper text-wrap p-2" dangerouslySetInnerHTML={{__html: sanitizeHtml(this.state.pDescription)}}>
                                        
                                    </div>
                                </Tab>}

                                <Tab eventKey="2" title="Özellikler">
                                    <div className="tab-item-wrapper p-2">
                                        <table className="table table-striped table-hover">
                                        <tbody>
                                        {this.props.veri.property_label_1 && <tr><td className="col-3"><strong>{this.props.veri.property_label_1}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_1}</td></tr>}
                                        {this.props.veri.property_label_2 && <tr><td className="col-3"><strong>{this.props.veri.property_label_2}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_2}</td></tr>}
                                        {this.props.veri.property_label_3 && <tr><td className="col-3"><strong>{this.props.veri.property_label_3}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_3}</td></tr>}
                                        {this.props.veri.property_label_4 && <tr><td className="col-3"><strong>{this.props.veri.property_label_4}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_4}</td></tr>}
                                        {this.props.veri.property_label_5 && <tr><td className="col-3"><strong>{this.props.veri.property_label_5}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_5}</td></tr>}
                                        {this.props.veri.property_label_6 && <tr><td className="col-3"><strong>{this.props.veri.property_label_6}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_6}</td></tr>}
                                        {this.props.veri.property_label_7 && <tr><td className="col-3"><strong>{this.props.veri.property_label_7}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_7}</td></tr>}
                                        {this.props.veri.property_label_8 && <tr><td className="col-3"><strong>{this.props.veri.property_label_8}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_8}</td></tr>}
                                        {this.props.veri.property_label_9 && <tr><td className="col-3"><strong>{this.props.veri.property_label_9}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_9}</td></tr>}
                                        {this.props.veri.property_label_10 && <tr><td className="col-3"><strong>{this.props.veri.property_label_10}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_10}</td></tr>}
                                        {this.props.veri.property_label_11 && <tr><td className="col-3"><strong>{this.props.veri.property_label_11}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_11}</td></tr>}
                                        {this.props.veri.property_label_12 && <tr><td className="col-3"><strong>{this.props.veri.property_label_12}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_12}</td></tr>}
                                        {this.props.veri.property_label_13 && <tr><td className="col-3"><strong>{this.props.veri.property_label_13}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_13}</td></tr>}
                                        {this.props.veri.property_label_14 && <tr><td className="col-3"><strong>{this.props.veri.property_label_14}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_14}</td></tr>}
                                        {this.props.veri.property_label_15 && <tr><td className="col-3"><strong>{this.props.veri.property_label_15}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_15}</td></tr>}
                                        </tbody>
                                        </table>
                                    </div>
                                </Tab>

                                {this.state.pAuctionQuestions.length>0 && <Tab eventKey="3" title="Soru & Cevap">
                                    <div className="tab-item-wrapper p-2">
                                        {this.state.pAuctionQuestions && this.state.pAuctionQuestions.map((qid, index) => {
                                            return (
                                                <div className="chat-body no-padding profile-message mt-3">
                                                <ul>
                                                    <li className="message">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#5dd903" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                                                        <span className="message-text">
                                                            <span className="username">
                                                            {qid.question_from_name}
                                                            </span>
                                                            <br />
                                                            {qid.question_body}</span>
                                                    </li>
                                                    {qid.auction_answer && qid.auction_answer.map((ansid) => {
                                                        return (
                                                    <li className="message m-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#5dd903" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                                                        <span className="message-text">
                                                            <span className="username">
                                                            {ansid.answer_from_name}
                                                            </span>
                                                            <br />
                                                            {ansid.answer_body}</span>
                                                    </li>)})}
                                                </ul>
                                            </div>)
                                        })}
                                        </div>
                                </Tab>}

                                {this.state.pSellerRatings.length>0 && <Tab eventKey="4" title="Satıcı Yorumları">
                                    <div className="tab-item-wrapper">
                                        <br />

                                        <div className="chat-body no-padding profile-message">
                                            <ul>                                                  
                                                {this.state.pSellerRatings && this.state.pSellerRatings.map((rating) => {
                                                        return (
                                                    <li className="message pb-3">
                                                        <img src="./assets/images/avatar01.png" className="online rounded-circle p-1" alt={rating.rated_by_user_title} />
                                                        <span className="message-text">
                                                            <span className="username">
                                                            {rating.rated_by_user_title}
                                                            <span className="pull-right">
                                                               {rating.rating && <Stars rating={rating.rating} ratinggoster={true} allowChange={false}/>}
                                                            </span>

                                                            <span className="badge">{rating.approved? 'Ürünü Onayladı': 'Ürünü onaylamadı'}</span>
                                                            </span>
                                                            <br/>
                                                            {rating.comment}
                                                            <ul className="list-inline font-xs">
                                                                <li className="pull-right">
                                                                <small className="text-muted pull-right ultra-light"> 1 ay önce </small>
                                                                </li>
                                                            </ul>
                                                            </span>
                                                    </li>)})}
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>}

                            </Tabs>
                        </div>

                    </div>
                </div>
            </div>)
    }
}

export default withCookies(PrevAuctionDetailsChild);