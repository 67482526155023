import React, {Component} from 'react';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export class Zoom extends Component {
    constructor(props) {
        super(props);
      }
    render(){
      return (
        <TransformWrapper initialScale={1} minScale={1} maxScale={8} centerZoomedOut={false} centerOnInit={false}>
            <TransformComponent>
              <div className="col-12">
                <img src={this.props.imageUrl} alt=""/>
              </div>
            </TransformComponent>
        </TransformWrapper>
      )
    }   
  }
  export default Zoom;