import React, {Component} from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import UserLeftMenu from './userleftmenu';
import NotAuthorized from '../notauthorized';
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import DateCombo from '../../components/datecombo/datecombo';
import ModalForm from '../../components/modal/modalform';

import UserMobileMenu from './usermobilemenu';
import swal from 'sweetalert2';

export class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailVerification = this.handleEmailVerification.bind(this);
    }

    state = {
        errorDesc: '',
        isLoading: false,
        isAuthenticated: false,
        isOpen: false,
        activeModal: null,
        err: false,
        title: '',
        firstName: '',
        lastName: '',
        TCKN: '',
        phone: '',
        gun: 1,
        ay: 1,
        yil: 1980,
        verification_code: '',
        email_verified_at: null,
        bankIban: '',
        bankAccountName: '',
    }
    openModal = () => this.setState({isOpen: true});
    closeModal = () => this.setState({isOpen: false, activeModal: null});
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    handleEmailVerification = event => {
        event.preventDefault();
        var retype = 'success';
        const {cookies} = this.props;
        //if (this.state.verification_code.length===4) {
        //var formData = new FormData()
        //formData.append('verification_code',  this.state.verification_code);

        fetch(this.props.apiurl + '/api/email/verification-notification', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include'
            //body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                } else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: "error",
                        text: "Profil detayı güncellemek için üye girişi yapmanız gerekiyor",
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                } else {
                    this.setState({isOpen: false, activeModal: null});
                    swal.fire({
                        icon: retype,
                        text: responseData.message,
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text: 'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                });
            });
    }


    handlePhoneVerification = event => {
        event.preventDefault();
        var retype = 'success';
        const {cookies} = this.props;
        if (this.state.verification_code.length === 4) {
            var formData = new FormData()
            formData.append('verification_code', this.state.verification_code);

            fetch(this.props.apiurl + '/api/verifyPhone', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + cookies.get('access-token'),
                },
                credentials: 'include',
                body: formData
            })
                .then((response) => {
                    if (!response.ok) {
                        retype = 'error';
                        return response.json();
                    } else {
                        retype = 'success';
                        return response.json();
                    }
                })
                .then((responseData) => {
                    this.setState({isOpen: false, activeModal: null});
                    if (responseData.message === 'Unauthenticated.') {
                        swal.fire({
                            icon: 'error',
                            text: "Profil detayı güncellemek için üye girişi yapmanız gerekiyor",
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                        });
                    } else if (responseData.message === "Geçersiz doğrulama kodu") {
                        swal.fire({
                            icon: 'error',
                            text: "Geçersiz doğrulama kodu",
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                        });
                    } else {
                        swal.fire({
                            icon: retype,
                            text: responseData.message,
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                        });
                    }
                })

                .catch((error) => {
                    swal.fire({
                        icon: 'error',
                        text: 'Bir hata oluştu. ' + error,
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                });
        } else {
            swal.fire({
                icon: 'warning',
                text: 'Geçersiz TC Kimlik No. Lütfen 11 haneli TC Kimlik Numaranızı girin',
                timer: 5000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
            });
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        var retype = 'success';
        const {cookies} = this.props;
        if (this.state.TCKN.length === 11) {
            var formData = new FormData()
            formData.append('title', this.state.title);
            formData.append('Ad', this.state.firstName);
            formData.append('Soyad', this.state.lastName);
            formData.append('TCKN', this.state.TCKN);
            formData.append('DogumGunu', this.state.gun);
            formData.append('DogumAyi', this.state.ay);
            formData.append('DogumYili', this.state.yil);
            formData.append('Telefon', this.state.phone);
            formData.append('HesapAdi', this.state.bankAccountName);
            formData.append('IBAN', this.state.bankIban);

            fetch(this.props.apiurl + '/api/setProfileDetails', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + cookies.get('access-token'),
                },
                credentials: 'include',
                body: formData
            })
                .then((response) => {
                    if (!response.ok) {
                        retype = 'error';
                        return response.json();
                    } else {
                        retype = 'success';
                        return response.json();
                    }
                })
                .then((responseData) => {
                    if (responseData.message === 'Unauthenticated.') {
                        swal.fire({
                            icon: 'error',
                            text: "Profil detayı güncellemek için üye girişi yapmanız gerekiyor",
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                        });
                    } else if (responseData.message === "Kimlik bilgileri geçersiz") {
                        swal.fire({
                            icon: 'error',
                            text: "Kimlik bilgileri doğrulanamadı",
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                        });
                    } else {
                        swal.fire({
                            icon: retype,
                            text: responseData.message,
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                        });
                        this.setState({isOpen: true, activeModal: 0})
                    }
                })

                .catch((error) => {
                    swal.fire({
                        icon: 'error',
                        text: 'Bir hata oluştu. ' + error,
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                });
        } else {
            swal.fire({
                icon: 'warning',
                text: 'Geçersiz TC Kimlik No. Lütfen 11 haneli TC Kimlik Numaranızı girin',
                timer: 5000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
            });
        }
    }

    componentDidMount() {
        this.setState({isLoading: true, isAuthenticated: false});
        const {cookies} = this.props;

        fetch(this.props.apiurl + '/api/user', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include'
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json();
                } else {
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    this.setState({isLoading: false, isAuthenticated: false})
                } else {
                    if (responseData) {
                        this.setState({
                            title: responseData.user.title,
                            firstName: responseData.user.first_name,
                            lastName: responseData.user.last_name,
                            phone: responseData.user.phone_1,
                            email: responseData.user.email,
                            TCKN: responseData.user.tckn,
                            email_verified_at: responseData.user.email_verified_at,
                            gun: parseInt(responseData.user.birth_day),
                            ay: parseInt(responseData.user.birth_month),
                            yil: parseInt(responseData.user.birth_year),
                            isLoading: false,
                            isAuthenticated: true,
                            bankAccountName: responseData.user.bank_account_name,
                            bankIban: responseData.user.bank_iban,
                        })
                    }
                }
            })

            .catch((error) => {
                this.setState({errorDesc: error});
            });
    }

    render() {
        document.title = "Mezatvar - Hesap Bilgilerim"
        if (this.state.isAuthenticated === true) {
            return (
                !this.state.isLoading &&
                <div className="App">
                    <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                    <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>

                    <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">

                        <nav aria-label="breadcrumb" className="main-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Hesap Bilgilerim</li>
                            </ol>
                        </nav>

                        <div className="row gutters-sm">
                            <div className="col-md-4 d-none d-md-block">
                                <UserLeftMenu activetab={this.props.activetab}/>
                            </div>
                            <div className="col-md-8">
                                <div className="card">
                                    <UserMobileMenu activetab={this.props.activetab}/>
                                    <div className="card-body tab-content">
                                        <div className="tab-pane active" id="profile">
                                            <h6>HESAP BİLGİLERİM</h6>
                                            <hr/>
                                            {!this.state.email_verified_at ?
                                                <div className="alert alert-warning" role="alert">E-posta adresinizi
                                                    doğrulamanız gerekmektedir.
                                                    <button className="btn btn-sm btn-green float-right"
                                                            onClick={this.handleEmailVerification}>E-posta adresinizi
                                                        doğrulamak için tıklayın
                                                    </button>
                                                </div> : null}
                                            <form autocomplete="off" onSubmit={this.handleSubmit}>
                                                <div className="d-flex m-0 p-0">
                                                    <div className="form-group col-12 m-0 p-0">
                                                        <label htmlFor="title">Görünen Ad <span
                                                            className="smalltext pull-right">(Kargo bilgilerinde ve diğer kullanıcılar ile etkileşimlerinizde bu isim kullanılacaktır)</span></label>
                                                        <input type="text" className="form-control" id="title"
                                                               name="title"
                                                               onChange={this.handleChange}
                                                               placeholder="Diğer kullanıcılar ile etkileşimlerinizde bu isim kullanılacaktır"
                                                               value={this.state.title} maxLength="50" required/>
                                                    </div>
                                                </div>
                                                <div className="d-flex m-0 p-0">
                                                    <div className="form-group col-6 m-0 p-0">
                                                        <label htmlFor="firstName">Adınız</label>
                                                        <input type="text" className="form-control" id="firstName"
                                                               name="firstName"
                                                               onChange={this.handleChange} placeholder="Adınız"
                                                               value={this.state.firstName}
                                                               maxLength="50" required/>
                                                    </div>
                                                    <div className="form-group col-6 mr-0 pr-0">
                                                        <label htmlFor="lastName">Soyadınız</label>
                                                        <input type="text" className="form-control" id="lastName"
                                                               name="lastName"
                                                               onChange={this.handleChange} placeholder="Soyadınız"
                                                               value={this.state.lastName}
                                                               maxLength="50" required/>
                                                    </div>
                                                </div>
                                                <div className="d-flex m-0 p-0">
                                                    <div className="form-group col-6 m-0 p-0">
                                                        <label htmlFor="TCKN">TC Kimlik Numaranız</label>
                                                        <input type="text" className="form-control" id="TCKN"
                                                               name="TCKN"
                                                               onChange={this.handleChange}
                                                               placeholder="11 haneli TC Kimlik No"
                                                               value={this.state.TCKN} minLength="11" maxLength="11"
                                                               required/>
                                                    </div>
                                                    <div className="form-group col-6 mr-0 pr-0">
                                                        <label>Doğum Tarihiniz</label>
                                                        <DateCombo selectedDay={this.state.gun}
                                                                   selectedMonth={this.state.ay}
                                                                   selectedYear={this.state.yil}
                                                                   handleChange={this.handleChange}/>

                                                    </div>
                                                </div>
                                                <div className="form-group col-6 m-0 p-0">
                                                    <label htmlFor="phone">Telefon No (Cep)</label>
                                                    <input type="tel" className="form-control" id="phone" name="phone"
                                                           onChange={this.handleChange} placeholder="(5**) *******"
                                                           value={this.state.phone} minLength="10" maxLength="12"
                                                           required/>

                                                </div>

                                                <div className="form-group col-6 m-0 p-0">
                                                    <label htmlFor="bankAccountName">Hesap İsmi</label>
                                                    <input type="text" className="form-control" id="bankAccountName"
                                                           name="bankAccountName"
                                                           onChange={this.handleChange}
                                                           placeholder="HESAPTA KAYITLI OLAN İSİM"
                                                           value={this.state.bankAccountName} maxLength="50"
                                                           required/>

                                                </div>

                                                <div className="form-group col-6 m-0 p-0">
                                                    <label htmlFor="bankIban">IBAN</label>
                                                    <input type="text" className="form-control" id="bankIban"
                                                           name="bankIban"
                                                           onChange={this.handleChange}
                                                           placeholder="TR06 xxxx xxxx xxxx xxxx xx"
                                                           pattern="TR[0-9]{24}"
                                                           value={this.state.bankIban} minLength="20" maxLength="26"
                                                           required/>

                                                </div>

                                                <div className="form-group small text-muted">
                                                    Hesap isminiz ile IBAN bilgilerinizin uyuşması gerekmektedir.
                                                    Aksi halde ödemeleriniz yapılamayabilir.
                                                </div>

                                                <div className="form-group small text-muted">
                                                    Satış yapabilmeniz ve mezatlara katılabilmeniz için bu bilgileri
                                                    doldurmanız gerekmektedir.
                                                    Verilerinizin nasıl işleneceğine dair detayları Gizlilik
                                                    sözleşmemizde ve Kişisel verilerin
                                                    kullanımı ve çerezler politikamızda bulabilirsiniz.
                                                </div>
                                                <button type="submit" className="btn btn-blue">Profili Güncelle</button>
                                                <button type="reset" className="btn btn-outline-dark ml-2">Değişikleri
                                                    İptal Et
                                                </button>
                                            </form>
                                        </div>
                                        {this.state.activeModal === 0 && this.state.isOpen ?
                                            <ModalForm
                                                activeModal={this.activeModal}
                                                closeModal={this.closeModal}
                                                isOpen={this.state.isOpen}
                                                onHide={this.closeModal}
                                                handleSubmit={this.handlePhoneVerification}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                title="Telefon Numarası Doğrulama"
                                                saveButtonCaption="Doğrula"
                                                closeButtonCaption='Kapat'
                                            >
                                                <h6>Lütfen SMS ile tarafınıza gönderilen 4 haneli doğrulama kodunu
                                                    giriniz</h6>
                                                <input type="text" id="verification_code" name="verification_code"
                                                       className="form-control" maxLength="4" minLength="4"
                                                       onChange={this.handleChange}/>
                                            </ModalForm>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
                    <FooterSection apiurl={this.props.apiurl}/>
                </div>
            )
        } else {
            return (!this.state.isLoading &&
                <div className="App">
                    <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                    <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
                    <NotAuthorized/>
                    <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
                    <FooterSection apiurl={this.props.apiurl}/>
                </div>
            )
        }

    }

}


export default withCookies(MyAccount);