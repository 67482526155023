import React, {Component} from 'react';
import swal from 'sweetalert2';

class SwalBanner extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }


      componentDidMount() {
        if (this.props.banner_image!=null) {
          const { innerWidth: width, innerHeight: height } = window;
          var imageUri = (window.innerWidth<650) ? this.props.banner_image_mobile: this.props.banner_image;
          var imageWidth = (window.innerWidth<650) ? 320: 750;
          var imageTitle = this.props.banner_title;
          var imageTarget = this.props.banner_target;
          var imageLink = this.props.banner_link;
              swal.fire({
                html: '<a href=' + imageLink + ' target=' + imageTarget +' title=' + imageTitle +'><img src=' + imageUri + ' alt=' + imageTitle +'/></a>',
                width: imageWidth,
                timer: 5000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
              });
          }
        }
        
    render() {
        return (
            <React.Fragment></React.Fragment>
        )
    }
}


export default SwalBanner;