import React, { Component } from 'react';
import './Slider.css';
import BtnSlider from './BtnSlider';
import Zoom from '../zoom/zoom';

class Slider extends Component {
    /***Props
     * withDots: true //shows dots
     * withThumbs: true //shows thumbs
     * imageList: images to show (array with id, public_url)
     * productList: product cards to show
     * apiUrl: URL for restAPI
     */

    state = {
        slideIndex: 1,
        mouseStatus: false,
    }

    nextSlide = () => {
        if (this.props.imageList && this.props.imageList.length > 0) {
            if (this.state.slideIndex !== this.props.imageList.length) {
                this.setState({ slideIndex: this.state.slideIndex + 1 })
            }
            else if (this.state.slideIndex === this.props.imageList.length) {
                this.setState({ slideIndex: 1 })
            }
        } else {
            this.setState({ slideIndex: 1 })
        }
    }

    prevSlide = () => {
        if (this.props.imageList && this.props.imageList.length > 0) {
            if (this.state.slideIndex !== 1) {
                this.setState({ slideIndex: this.state.slideIndex - 1 })
            }
            else if (this.state.slideIndex === 1) {
                this.setState({ slideIndex: this.props.imageList.length })
            }
        } else {
            this.setState({ slideIndex: 1 })
        }
    }

    moveDot = (index) => {
        this.setState({ slideIndex: index })
    }

    showButtons = () => {
        this.setState({ mouseStatus: true })
    }

    hideButtons = () => {
        this.setState({ mouseStatus: false })
    }

    render() {
        return (

            <div className={this.props.frameClassName}>
 
                <div className={this.props.containerClassName} onMouseMove={this.showButtons} onMouseLeave={this.hideButtons}>

                            {this.props.imageList && this.props.imageList.map((img1, index) => {
                                return (
                                <div id={'div' + index} key={'div' + index} className={this.state.slideIndex === index + 1 ? "slide active-anim" : "slide"}>
                                    {this.props.zoom && <Zoom key={'zoom' + index} imageUrl={img1.image_src ? img1.image_src : this.props.apiurl + img1.public_url}></Zoom>}
                                    {!this.props.zoom && (!this.props.withLinks ? <img key={'img'+ index} src={img1.image_src ? img1.image_src : this.props.apiurl + img1.public_url} alt=""/>: <a href={img1.link_url} target={img1.link_target}><img key={'img'+ index} src={img1.image_src ? img1.image_src : this.props.apiurl + img1.public_url} alt=""/></a>)}
                                </div>
                                   
                                )
                            })}

                        {this.props.imageList.length > 1 && this.state.mouseStatus && <BtnSlider moveSlide={this.nextSlide} direction={"next"} />}
                        {this.props.imageList.length > 1 && this.state.mouseStatus && <BtnSlider moveSlide={this.prevSlide} direction={"prev"} />}

                        {this.props.withDots && this.props.imageList.length > 1 && <div className="container-dots">

                            {this.props.imageList.length > 1 && Array.from({ length: this.props.imageList.length }).map((item, index) => (
                                <div key={'dot' + index}
                                    onClick={() => this.moveDot(index + 1)}
                                    className={this.state.slideIndex === index + 1 ? "dot active" : "dot"}
                                ></div>
                            ))}
                        </div>}

                </div>

                {this.props.withThumbs && this.props.imageList.length > 1 && <div className="container-thumbnail">
                    {this.props.imageList && this.props.imageList.map((img1, index) => {
                        return (
                            <div
                                key={'thumb' + img1.id}
                                className={this.state.slideIndex === index + 1 ? "thumbnail active m-1 p-1" : "thumbnail m-1 p-1"}
                                onClick={() => this.moveDot(index + 1)}>
                                <img key={img1.id}
                                    src={img1.image_src ? img1.image_src : this.props.apiurl + img1.public_url} alt=""
                                    loading="lazy" />
                            </div>
                        )
                    })}
                </div>}
            </div>

        )
    }
}
export default Slider;