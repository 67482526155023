import React from "react";
//import { FormGroup, Label, Input } from "reactstrap";
import '../../assets/css/richtext.css';


const Step2 = props => {
  if (props.currentStep !== 2) {
    return null;
  }

  function handleContentEdit(e) {
    e.preventDefault();
    var commandName = e.target.getAttribute("data-command");
    document.execCommand(commandName, false);
  }

  var  bulundu=false;
  return (
    <>
      <form>
        <div className="row">
        <div className="col-md-6">
          <div className="text-left p-1">Ürün Başlığı</div>
          <div><input className="form-control"
            type="text"
            name="urunismi"
            id="urunismi"
            placeholder="Ürün başlığını girin"
            value={props.urunismi} // Prop: The username input data
            onChange={props.handleChange} // Prop: Puts data into the state
          /></div>
        </div>
        <div className="col-md-6">
          
        </div>
        <div className="col-md-6">
          <div className="text-left p-1">Açıklama</div>
          <div>
            <div className="toolbar">
              <ul className="tool-list">
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="bold"
                    title='Kalın'
                  >
                    <i className=' fas fa-bold'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="italic"
                    title='İtalik'
                  >
                    <i className=' fas fa-italic'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="underline"
                    title='Altını Çiz'
                  >
                    <i className=' fas fa-underline'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="strikeThrough"
                    title='Üstünü Çiz'
                  >
                    <i class="fas fa-strikethrough"></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command='justifyLeft'
                    title='Sola Yasla'
                  >
                    <i className=' fas fa-align-left'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command='justifyCenter'
                    title='Ortala'
                  >
                    <i className=' fas fa-align-center'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command='justifyRight'
                    title='Sağa Yasla'
                  >
                    <i className=' fas fa-align-right'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="indent"
                    title='Paragraf Girintisi'
                  >
                    <i class="fas fa-indent" ></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="outdent"
                    title='Dışarı Çek'
                  >
                    <i class="fas fa-outdent"></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="insertOrderedList"
                    title='Maddeli Liste'
                  >
                    <i className=' fas fa-list-ol'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="insertUnorderedList"
                    title='Noktalı Liste'
                  >
                    <i className=' fas fa-list-ul'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="insertHorizontalRule"
                    title='Yatay çizgi çek'
                  >
                    <strong>-</strong>
                  </button>
                </li>

                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="removeFormat"
                    title='Biçimlendirmeyi kaldır'
                  >
                    <i class="fas fa-eraser"></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="undo"
                    title='Geri Al'
                  >
                    <i className=' fas fa-undo'></i>
                  </button>
                </li>
                <li className="tool">
                  <button onClick={handleContentEdit}
                    className="tool--btn"
                    data-command="redo"
                    title='İleri Git'
                  >
                    <i className=' fas fa-redo'></i>
                  </button>
                </li>

              </ul>
            </div>

            <div id="description" name='description' onInput={props.handleEditableContent} className="form-control text-left" contentEditable="true"></div>

          </div>
        </div>
        <div className="col-md-6">
            {props.categories && props.categories.filter(cat => cat.id == props.altkategori || cat.id == props.kategori || cat.id == props.anakategori).sort().reverse().map(altcat => {
              if (bulundu==false && altcat.property_group) {
                  return props.propGroups && props.propGroups.filter(p => p.id==altcat.property_group).map((e, key) => {
                    bulundu=true;
                    return <>{e.label_1_title && <div className="text-left">{e.label_1_title}</div>}
                    {e.label_1_values && <div className=""><select name="property_1" id="property_1" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_1_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_2_title && <div className="text-left">{e.label_2_title}</div>}
                    {e.label_2_values && <div className=""><select name="property_2" id="property_2"  className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_2_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_3_title && <div className="text-left">{e.label_3_title}</div>}
                    {e.label_3_values && <div className=""><select name="property_3" id="property_3" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_3_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_4_title && <div className="text-left">{e.label_4_title}</div>}
                    {e.label_4_values && <div className=""><select name="property_4" id="property_4" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_4_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_5_title && <div className="text-left">{e.label_5_title}</div>}
                    {e.label_5_values && <div className=""><select name="property_5" id="property_5" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_5_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_6_title && <div className="text-left">{e.label_6_title}</div>}
                    {e.label_6_values && <div className=""><select name="property_6" id="property_6" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_6_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_7_title && <div className="text-left">{e.label_7_title}</div>}
                    {e.label_7_values && <div className=""><select name="property_7" id="property_7" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_7_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_8_title && <div className="text-left">{e.label_8_title}</div>}
                    {e.label_8_values && <div className=""><select name="property_8" id="property_8" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_8_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_9_title && <div className="text-left">{e.label_9_title}</div>}
                    {e.label_9_values && <div className=""><select name="property_9" id="property_9" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_9_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_10_title && <div className="text-left">{e.label_10_title}</div>}
                    {e.label_10_values && <div className=""><select name="property_10" id="property_10" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_10_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_11_title && <div className="text-left">{e.label_11_title}</div>}
                    {e.label_11_values && <div className=""><select name="property_11" id="property_11" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_11_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_12_title && <div className="text-left">{e.label_12_title}</div>}
                    {e.label_12_values && <div className=""><select name="property_12" id="property_12" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_12_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_13_title && <div className="text-left">{e.label_13_title}</div>}
                    {e.label_13_values && <div className=""><select name="property_13" id="property_13" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_13_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_14_title && <div className="text-left">{e.label_14_title}</div>}
                    {e.label_14_values && <div className=""><select name="property_14" id="property_14" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_14_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                    {e.label_15_title && <div className="text-left">{e.label_15_title}</div>}
                    {e.label_15_values && <div className=""><select name="property_15" id="property_15" className="form-control" onChange={props.handleChange}><option value="">Lütfen Seçin</option>{e.label_15_values.split(',').map(r => <option value={r}>{r}</option>)}</select></div>}
                  </>
                }) }

            })
              }              
        </div>
        </div>                
      </form>
    </>
  );
};

export default Step2;
