import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import SalesFormMaster from "../components/salesform/salesformmaster.js";
import NotAuthorized from './notauthorized';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

export class SalesPage extends Component {
  state = {
    errorDesc:'',
    categoryurl: this.props.apiurl + '/api/categories',
    brandurl: this.props.apiurl + '/api/brands',
    propgroupurl: this.props.apiurl + '/api/propertygroups',
    isShown: false,
    activedivid: 0,
    authCheckUrl: this.props.apiurl + '/api/user',
    isAuthenticated: false,
    isLoading: true,
    categories: [],
    brands: [],
    propGroups: [],
  }


  componentDidMount() {
    this.setState({ isLoading: true, isAuthenticated: false });
    const { cookies } = this.props;
    Promise.all([
      fetch(this.state.categoryurl, {method: 'GET', credentials: 'include'}).then(res => res.json()),
      fetch(this.state.brandurl, {method: 'GET', credentials: 'include'}).then(res => res.json()),
      fetch(this.state.propgroupurl, {method: 'GET', credentials: 'include'}).then(res => res.json()),
      fetch(this.state.authCheckUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token'),
        },
        credentials: 'include'
      }
      ).then(res => res.json())
    ]).then(([categoryData, brandData, propData, urlTwoData]) => {
      this.setState({
        categories: categoryData, brands: brandData, propGroups: propData
      });
      if (urlTwoData.message === 'Unauthenticated.') {
        this.setState({ isLoading: false, isAuthenticated: false })
      } else {
        this.setState({ isLoading: false, isAuthenticated: true })
      }

    }).catch((error) => {
      this.setState({errorDesc: error});
    });
  }

  goster(e, divtoshow) {
    this.setState({ isShown: e });
    this.setState({ activedivid: divtoshow });
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  render() {
    document.title = "Mezatvar - Satış Yap";
    if (this.state.isAuthenticated === true) {
      return (
        !this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded"><SalesFormMaster categories={this.state.categories} brands={this.state.brands} propGroups={this.state.propGroups} apiurl={this.props.apiurl} /></div>
          <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection />
        </div>
      )
    } else {
      return !this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          <NotAuthorized/>
          <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl} />
        </div>
    }

  }
}

export default withCookies(SalesPage);