import React, { Component } from 'react';

import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import '../assets/css/registration.css';
import ModalForm from '../components/modal/modalform';
import sanitizeHtml from 'sanitize-html';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Video from '../parts/video';
import swal from 'sweetalert2';


export class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      passwordShown: false,
      password2Shown: false,
      isOpen: false,
      activeModal: null,
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
      isLoading: "",
      passwordStrength: 20,
      msg: "",
      membership_data:"",
      privacy_data:"",
    }
  }
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false, activeModal: null });
  handleSubmitMember = (name) => {
    this.setState({ isOpen: false });
    document.getElementById("reg_accept_membership").checked = true;
  }
  handleSubmitPrivacy = (name) => {
    this.setState({ isOpen: false });
    document.getElementById("reg_accept_privacy").checked = true;
  }

  clickHandler(e, index) {
    e.preventDefault();
    this.setState({ isOpen: true, activeModal: index })
  }

  togglePasswordVisibility = () => {
    this.state.passwordShown ? this.setState({ passwordShown: false }) : this.setState({ passwordShown: true })
  }

  togglePassword2Visibility = () => {
    this.state.password2Shown ? this.setState({ password2Shown: false }) : this.setState({ password2Shown: true })
  }

  componentDidMount() {
    this.getMembershipData();
    this.getPrivacyData();
  }
  getMembershipData() {
    const apiUrl = this.props.apiurl + '/api/contracts/uyelik-sozlesmesi';
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'            
      },
      credentials: 'include'
    })
      .then(res => res.json())
      .then((data) => {
        data.contract_data && this.setState({ membership_data: data.contract_data})
      })

      .catch(err => {
        swal({
          title: "Hata",
          text: err,
          icon: "error",
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      })
    
  }
  getPrivacyData() {
    const apiUrl = this.props.apiurl + '/api/contracts/gizlilik-sozlesmesi';
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'            
      },
      credentials: 'include',
    })
      .then(res => res.json())
      .then((data) => {
        data.contract_data && this.setState({ privacy_data: data.contract_data})
      })

      .catch(err => {
        swal({
          title: "Hata",
          text: err,
          icon: "error",
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      })
    
  }
  handleFormSubmit = (event) => {
    event.preventDefault();
    var retype = 'success';
    //const url = new URL(document.referrer)
    this.setState({ isLoading: true });
    const { cookies } = this.props;
    if (document.getElementById("password").value === document.getElementById("password_confirmation").value) {
      if ((document.getElementById("reg_accept_membership").checked === true) && (document.getElementById("reg_accept_privacy").checked === true)) {

        fetch(this.props.apiurl + '/api/register', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            "username": this.state.username,
            "email": this.state.email,
            "password": this.state.password,
            "password_confirmation": this.state.password_confirmation,
          })
        })
          .then((response) => {
            if (!response.ok) {
              retype = 'error';
              return response.json();
            }
            else {
              retype = 'success';
              return response.json();
            }
          })
          .then((responseData) => {
            swal.fire({icon: retype,
              text: responseData.message,
              timer: 5000,
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: true,
              closeButtonCaption: 'Kapat'
            });
            if (responseData.status==="success") {
              cookies.set('access-token', responseData.token, { path: '/' });
              window.location="/u/profilim"
            }
           
          })

          .catch((error) => {
            this.setState({errorDesc: error});
          });

        this.setState({ isLoading: false });

      } else {
        swal.fire({icon: "error",
          text: "Kayıt yapabilmeniz için üyelik ve gizlilik sözleşmelerini kabul etmeniz gerekiyor",
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      }
    } else {
      swal.fire({icon: "error",
          text: "Şifre ve Şifre tekrar alanları birbirine eşit değil",
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
    }



  }
  render() {
    document.title = "Mezatvar - Yeni Üyelik Kaydı"
    return (
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
        <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
        <div className="container mb-4 mt-4">

          <div className="row text-left">

            <div className="col-md-4"></div>
            <div className="col-md-5">
              <div className="card cardbox">
                <div className="card-header text-center"><h2>Üye Ol</h2></div>
                <div className="card-body">


                  <div className="form-group">

                    <form className="form" onSubmit={this.handleFormSubmit}>

                      <div className="form-group">
                        <label className="sr-only">Kullanıcı Adınız</label>
                        <input type="text" id="username" name="username" className="form-control"
                          placeholder="Kullanıcı Adınız" onChange={this.handleChange} required />
                      </div>


                      <div className="form-group">


                        <label className="sr-only">Şifre</label>

                        <div className="input-group">

                          <input type={this.state.passwordShown ? "text" : "password"} id="password" name="password" className="form-control" placeholder="Şifre" onChange={this.handleChange} required />

                          <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" id="button-append1" onClick={this.togglePasswordVisibility}>
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>

                        <div className="progress mt-1" id="reg-password-strength">
                          <div id="password-strength" className="progress-bar progress-bar-success" value={this.state.passwordStrength} role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                          </div>
                        </div>

                        <div id="reg_passwordrules" className="hide password-rule mt-2"><small>

                          <ul className="list-unstyled">
                            <li className="">
                              <span className="eight-character"><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                              &nbsp; en az 8 karakter</li>
                            <li className="">
                              <span className="low-upper-case"><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                              &nbsp; en az 1 büyük & 1 küçük harf</li>
                            <li className="">
                              <span className="one-number"><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                              &nbsp; en az 1 rakam</li>
                          </ul>
                        </small></div>

                      </div>


                      <div className="form-group">


                        <label className="sr-only">Şifre (Tekrar)</label>

                        <div className="input-group">
                          <input type={this.state.password2Shown ? "text" : "password"} name="password_confirmation" id="password_confirmation" className="form-control" placeholder="Şifre (Tekrar)" onChange={this.handleChange} required />

                          <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" id="button-append2" onClick={this.togglePassword2Visibility}>
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </button>
                          </div>

                        </div>

                        <div className="help-block text-right">
                          <small><span id="error-confirmpassword" className="hide pull-right block-help">
                            <i className="fa fa-info-circle text-danger" aria-hidden="true"></i>Şifre ve şifre tekrar aynı değil</span></small>
                        </div>

                      </div>


                      <div className="form-group">
                        <label className="sr-only">E-posta Adresiniz</label>
                        <input type="email" id="email" name="email" className="form-control" onChange={this.handleChange} placeholder="E-posta adresiniz (örnek: isim@domain.com)" required />
                      </div>


                      <div className="form-group">
                        <input type="submit" id="reg_submit" name="submit" value="Üye Ol" className="btn btn-block btn-blue" />
                      </div>



                      <div className="form-check">
                        <input type="checkbox" id="reg_accept_membership" name="reg_accept_membership" className="form-check-input" value="2" />
                        <label className="form-check-label" for="reg_accept_membership"><a href="#" onClick={e => this.clickHandler(e, 0)}>Üyelik Sözleşmesini</a> okudum ve kabul ediyorum</label>
                      </div>
                      <div className="form-check">
                        <input type="checkbox" id="reg_accept_privacy" name="reg_accept_privacy" className="form-check-input" value="3" />
                        <label className="form-check-label" for="reg_accept_privacy"><a href="#" onClick={e => this.clickHandler(e, 1)}>Gizlilik Sözleşmesini</a> okudum ve kabul ediyorum</label>
                      </div>
                      {this.state.activeModal === 0 && this.state.isOpen ?
                        <ModalForm
                          activeModal={this.activeModal}
                          closeModal={this.closeModal}
                          isOpen={this.state.isOpen}
                          onHide={this.closeModal}
                          handleSubmit={this.handleSubmitMember}
                          aria-labelledby="contained-modal-title-vcenter"
                          title="Üyelik Sözleşmesi"
                          saveButtonCaption="Okudum ve Kabul Ediyorum"
                          closeButtonCaption='Kapat'
                        >

                          {this.state.membership_data && this.state.membership_data.map((contract) => {
                                  return <div className='row-md-12'>
                                      <div dangerouslySetInnerHTML={{__html: sanitizeHtml(contract.contract_content)}}>
                                      </div>
                                  </div>                            
                              } ) 
                          }
                          
                        </ModalForm>
                        :
                        null
                      }
                      {this.state.activeModal === 1 && this.state.isOpen ?
                        <ModalForm
                          activeModal={this.activeModal}
                          closeModal={this.closeModal}
                          isOpen={this.state.isOpen}
                          onHide={this.closeModal}
                          handleSubmit={this.handleSubmitPrivacy}
                          aria-labelledby="contained-modal-title-vcenter"
                          title="Gizlilik Sözleşmesi"
                          saveButtonCaption="Okudum ve Kabul Ediyorum"
                          closeButtonCaption='Kapat'
                        >
                                {this.state.privacy_data && this.state.privacy_data.map((contract) => {
                                  return <div className='row-md-12'>
                                      <div dangerouslySetInnerHTML={{__html: sanitizeHtml(contract.contract_content)}}>
                                      </div>
                                  </div>                            
                              } ) 
                          }
                          

                        </ModalForm>
                        :
                        null
                      }
                    </form>
                  </div>

                  <div className="login-or"><hr className="hr-or" /></div>

                  <div className="bottom text-center">
                    Zaten Kullanıcı mısınız? <a href="/girisyap"><b>Giriş Yap</b></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
              <div className="col-12"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
            </div>
        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl}/>
      </div>
    )
  }
}

export default withCookies(Registration);