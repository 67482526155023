import React, { Component } from 'react';

export class MezatSayaci extends Component {

  constructor(props) {
    super(props);
    this.state = {
      auctionstart: new Date(Date.parse(new Date(this.props.starttime))),
      auctionend: new Date(Date.parse(new Date(this.props.endtime))),
      servertime: new Date(Date.parse(this.props.servertime)),
      secondspassed: 0,
      countercaption: '',
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
      started: false,
      ended: false,
    }
    this.initializeClock = this.initializeClock.bind(this);
    this.getTimeRemaining = this.getTimeRemaining.bind(this);
    this.updateClock = this.updateClock.bind(this);
  }

  componentDidMount() {
    this.initializeClock();
  }

  initializeClock() {
    this.timeinterval = setInterval(this.updateClock, 1000);
  }

  updateClock() {
    let d;
    d = new Date(this.state.servertime);
    d.setSeconds(d.getSeconds() + this.state.secondspassed);
    const t = this.getTimeRemaining(this.state.auctionend, this.state.auctionstart, d);
    this.setState({
      days: ('0' + t.days).slice(-2), hours: ('0' + t.hours).slice(-2),
      minutes: ('0' + t.minutes).slice(-2), seconds: ('0' + t.seconds).slice(-2)
    })
    let sSeconds = this.state.secondspassed+1;
    this.setState({secondspassed: sSeconds})
    if (t.total <= 0) {
      if (this.state.ended) {
        clearInterval(this.timeinterval);
      }
    }
    /*clock.setAttribute("style", "background-color: #f43133; color: #ffffff");
    clock.innerHTML = "<span>Süre Bitti<br>Kazanan: H.S***</span>";
    if (placement==="card") {
      productPay.innerHTML = "";
    } else {
      if (placement==="details") {
        productDetailStatus.setAttribute("style", "background-color: #f43133; color: #ffffff");
        auctionStatus.innerHTML="<i className='fas fa-gavel'></i> Bu Mezat Sona Erdi";
        kalanSure.innerHTML="";
      }
    }*/

    /*else {
     if (t.days===0 && t.hours===0 && t.minutes===0 && t.seconds<60) {
       secondsSpan.setAttribute("style", "color: var(--firebrick2)");
     }
   }*/
    return t;

  }
  getTimeRemaining(endtime, starttime, servertime) {
    var total;
    //var g1 = new Date();
    //var g1 = servertime ? new Date(servertime.formatted) : new Date();
    var g1 = servertime ? new Date(Date.parse(servertime)) : new Date();
    /*if (starttime.getTime() < g1.getTime()) {
      this.setState({ countercaption: 'Bitmesine kalan süre' , started: true})
      total = (endtime) ? Date.parse(endtime) - Date.parse(g1) : 0;
    } else {
      this.setState({ countercaption: 'Başlamasına kalan süre', started:false  })
      total = (starttime) ? Date.parse(starttime) - Date.parse(g1) : 0;
    }*/

    if (starttime.getTime() > g1.getTime()) {
      this.setState({ started: false, ended: false, countercaption: 'Başlamasına kalan süre'})
      total = Date.parse(starttime) - Date.parse(g1);
    } else {
      this.setState({ started: true })
      total = Date.parse(endtime) - Date.parse(g1);
      if (endtime.getTime() > g1.getTime()) {
          this.setState({ ended: false, countercaption: 'Bitmesine kalan süre'})
      } else {
          this.setState({ ended: true, countercaption: 'Süre Bitti'})
      }
  }

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    }
  }
 

  render() {

    return (
      <div>
        <div id="countercaption" className="smalltext bg-primary text-white p-1">{this.state.countercaption}</div>
        <div className="clockdiv row justify-content-center" id={"clock" + this.props.id}>

          <div className=''>
            <span className="days">{this.state.days}</span>
            <div className="dayslabel smalltext">GÜN</div>
          </div>
          <div className=''>
            <span className="hours">{this.state.hours}</span>
            <div className="hourslabel smalltext">SAAT</div>
          </div>
          <div className=''>
            <span className="minutes">{this.state.minutes}</span>
            <div className="smalltext">DK.</div>
          </div>
          <div className=''>
            <span className="seconds">{this.state.seconds}</span>
            <div className="smalltext">SN.</div>
          </div>
        </div>
      </div>
    )
  }
}

export default MezatSayaci;