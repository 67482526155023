import React, { Component } from 'react';

export default class ImageUploadMultiple extends Component {

    fileObj = [];
    fileArray = [];
    fileArrayUp = [];

    constructor(props) {
        super(props)
        this.state = {
            files: []
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.openFileDialog = this.openFileDialog.bind(this)
        //this.uploadFiles = this.uploadFiles.bind(this)
    }

    uploadMultipleFiles(e) {
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            this.fileArrayUp.push(this.fileObj[0][i])
        }
        this.fileObj = [];
        this.setState({ files: this.fileArray })
        this.props.fileHandleChange(this.fileArrayUp)
    }

    /*uploadFiles(e) {
        e.preventDefault()
        alert(this.state.files);
    }*/
    openFileDialog = (e) => {
        e.preventDefault();
        document.getElementById('fileDialogId').click();
    }
    render() {
        return (
            <form>
                {this.state.files.length > 0 && <div className="form-group multi-preview">
                    {(this.fileArray || []).map(url => (
                        <div className="indiv rounded">
                            <img src={url} alt="..." />
                        </div>
                    ))}
                </div>}
                <div className="col-12 text-left"><button className="btn btn-sm btn-outline-primary" onClick={this.openFileDialog}><i class="fas fa-image"></i> Fotoğraf Yükle</button></div>
                <div className="hiddenfile">
                    <input type="file" value="" id="fileDialogId" className="form-control d-none" accept="image/*;capture=camera" onChange={this.uploadMultipleFiles} multiple />
                </div>
            </form >
        )
    }
}