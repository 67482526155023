import React, {Component} from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import OrderSellerDetails from './ordersellerdetails';
import NotAuthorized from '../notauthorized';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

export class OrderSeller extends Component {

    state = {
        errorDesc:'',
        isLoading: false,
        isAuthenticated: false,
        err: false,
        orderDetails:'',
      }
      static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };

      componentDidMount() {
        this.getOrderData();
      }

      getOrderData() {
        this.setState({isLoading:true, isAuthenticated: false});
        const { cookies } = this.props;
    
        fetch(this.props.apiurl + '/api/showOrderForSeller/' + this.props.orderid , {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + cookies.get('access-token') ,
            },
            credentials: 'include'
          })
          .then((response) => {
            if(!response.ok) {
                return response.json();
              }
              else{
                return response.json();
              } 
          })
          .then((responseData) => {
            if (responseData.message==='Unauthenticated.') {
              this.setState({ isLoading: false, isAuthenticated:false })
            } else {
                this.setState({ orderDetails: responseData.order, isLoading: false, isAuthenticated:true })
            }
           })
    
          .catch((error) => {
            this.setState({errorDesc: error});
          });
      }
      
    render() {
        document.title="Mezatvar - Siparişim"
        
        if (this.state.isAuthenticated===true) {
          return (
            !this.state.isLoading && 
            <div className="App">
              <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
              <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
              {this.state.orderDetails && <OrderSellerDetails veri={this.state.orderDetails} apiurl={this.props.apiurl} slug={this.props.slug}/>}
              {!this.state.orderDetails && <div className="card-body cart">
                                <div className="col-sm-12 empty-cart-cls text-center">
                                    <h1><i className="text-danger fas fa-hand-holding-usd"></i></h1>
                                    <h4><strong>Aradığınız Sipariş Bulunamadı</strong></h4>
                                    <h5>Bir yanlışlık olduğunu düşünüyorsanız tarafımızla <a href="iletisim">iletişim</a> kurun<br />
                                    </h5> <a href="/u/satislarim" className="btn btn-blue cart-btn-transform m-3" data-abc="true">Satışlarıma Dön</a>
                                </div>

                            </div>}

                    
              <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
              <FooterSection apiurl={this.props.apiurl}/>
            </div>
          )
        } else {
          return (!this.state.isLoading &&
            <div className="App">
                <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
                <NotAuthorized/>
                <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl} />
                <FooterSection apiurl={this.props.apiurl}/>
            </div>
          ) 
        }
        
    }
}

export default withCookies(OrderSeller);