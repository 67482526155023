import React, { Component } from 'react';
import Chatbox from '../components/chatbox';
import Product01 from './product01';

import ReactPlayer from 'react-player';



export class LiveStream extends Component {
  constructor(props) {
    super(props);
    this.getLiveAuctions = this.getLiveAuctions.bind(this);
    this.listenToChannels = this.listenToChannels.bind(this);

  }

  state = {
    errorDesc:'',
    url: this.props.apiurl + '/api/liveauction/' + this.props.live_session_id,
    isLoading: false,
    isOpen: false,
    activeModal: null,
    activePay: null,
    servertime: '',
    liveJson: []
  }

  componentDidMount() {
    this.listenToChannels();
    this.getLiveAuctions();
  }

  listenToChannels = () => {
    window.Echo.channel('liveauction')
      .listen('LiveAuctionChange', (e) => {
        this.getLiveAuctions();
      })
  }

  getLiveAuctions() {
    this.setState({ isLoading: true });
    fetch(this.state.url , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      credentials: 'include'
    })
      .then(res => res.json())
      .then((data) => {
          this.setState({ liveJson: data.data, servertime: data.server_time.formatted , isLoading: false })
      })
      .catch(err => {
        this.setState({errorDesc: err});
      })
  }
  
  canliyayinlinki() {
    const streamURL = this.props.liveURL;
    return streamURL;
  }



  render() {
    return (
      <div>
        <div id="livest" className="text-center shadow-lg pr-3 pl-3 pt-3 pb-0 mt-2 bg-light rounded livetab">
          <section>
            <div className="row d-flex-inline justify-content-center">
              <div className="col-lg-3 text-left pt-lg-0 pt-sm-2 h-100" id='liveproduct'>
                {this.state.liveJson.length>0 ? (this.state.liveJson.map((prodInd) => (
                  <Product01  key={prodInd.id} apiurl={this.props.apiurl} productid={prodInd.id} productname={prodInd.name} productslug={prodInd.slug} salelabel={prodInd.sale_label} badgetype={prodInd.badgetype} seller={prodInd.seller_title} rating={prodInd.rating} currentprice={prodInd.current_price} openingprice={prodInd.opening_price} buynowprice={prodInd.buynow_price} images={prodInd.auction_image} endtime={prodInd.end_time} starttime={prodInd.start_time} servertime={this.state.servertime} />
                ))):
                  <div className="product-grid h-100 rounded">
                      <div className="product-content h-100">
                        <img src="./assets/images/logo.png" alt={"Şu anda yayında bir mezat yok"}/>
                        <p className="text-dark mt-5 mb-5">Şu anda yayında bir mezat bulunmamaktadır</p>
                        <button className="btn btn-green mt-1">Mezat Listesi</button>
                      </div>
                  </div>
                }
              </div>
              <div className="col-lg-6 h-100" id='ytube'>
                <div className="player-wrapper h-100">
                  <ReactPlayer className="react-player" url={this.canliyayinlinki()} playing={true} controls={false} width="100%" height="100%"/>
                </div>
              </div>
              <div className="col-lg-3 text-left pt-lg-0 pt-sm-2 h-100" id='chatbox'>
                <Chatbox live_session_id={this.props.live_session_id} live_session_start={this.props.live_session_start} apiurl={this.props.apiurl}/>
              </div>

            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default LiveStream;