import React, { Component, memo} from 'react';

import Product01 from './product01';
import ReactPlayer from 'react-player';
export class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      videos: [],
      servertime:[]
      }
  }


    componentDidMount() {

        fetch(this.props.apiurl + '/api/videos/' + this.props.slug, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
            },
            credentials: 'include'
          })
          .then((response) => {
            if(!response.ok) {
                return response.json();
              }
              else{
                return response.json();
              } 
          })
          .then((responseData) => {
              if (responseData) {
                this.setState({ videos: responseData.video_data, servertime: responseData.server_time.formatted})
              }
           })
    
          .catch((error) => {
            this.setState({errorDesc: error});
          });
      }

    render() {
        return (
            <div className="">
                {this.state.videos && this.state.videos.map((video) =>{   
                    return (
                    <div key={"vidcont" + video.id} className="row text-center shadow-lg pr-3 pl-3 pt-3 pb-3 mt-2 rounded" style={{backgroundColor: video.video_bgcolor}}>
                      <div className="col-md-8">
                          <div className="player-wrapper">
                            <ReactPlayer key={"reactp" + video.id} className="react-player" url={video.video_url} controls={true} width="100%" height="100%"/>
                          </div>
                      </div>
                      <div className="col-md-4 justify-content-center align-self-center">
                            {video.auction && <Product01 key={"vid" + video.id} apiurl={this.props.apiurl} productid={video.auction.id} productname={video.auction.name} productslug={video.auction.slug} salelabel={video.auction.sale_label} badgetype={video.auction.badgetype} seller={video.auction.seller_title} rating={video.auction.rating} currentprice={video.auction.current_price} openingprice={video.auction.opening_price} buynowprice={video.auction.buynow_price} images={video.auction.auction_image} endtime={video.auction.end_time} starttime={video.auction.start_time} servertime={this.state.servertime} />}
                            {!video.auction &&  <picture>
                            <source media="(min-width:650px)" srcSet={video.video_image} />
                            <source media="(min-width:320px)" srcSet={video.video_image_mobile} />
                            <a href={video.video_image_link}><img className="img-fluid" src={video.video_image} alt={video.video_title}/></a>
                            </picture>
                            }
                      </div>
                    </div>
                    )
                } )}
               
            </div>
        )
    }
}

export default memo(Video);