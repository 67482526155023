import React, {Component} from 'react';
import {Helmet} from "react-helmet";

export class MetaManager extends Component {  

    render(){
      
        return (
          <Helmet>
            <title>{this.props.title} </title>
            <meta name="og:image" property="og:image" 
            content={this.props.og_image} 
            data-react-helmet="true"/>
            <meta property="og:title" content={this.props.title} data-react-helmet="true"/>
            <meta property="og:description" content={this.props.description} data-react-helmet="true"/>
            <meta property="og:url" content={this.props.og_url} data-react-helmet="true"/>
            <meta property="og:site_name" content={this.props.sitename} data-react-helmet="true" />
            <meta name="twitter:card" content={this.props.og_image} data-react-helmet="true"/>
            <meta name="twitter:image:alt" content={this.props.og_imageAlt} data-react-helmet="true"/>
          </Helmet>
        )
    

        
        }
      

}

export default MetaManager;