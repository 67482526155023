import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import PrevAuctionDetailsChild from './prevauctiondetailschild';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Swal from "sweetalert2";

export class PrevAuctionDetails extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    err: false,
    errorDesc:'',
    url: this.props.apiurl + '/api/previewauction/' + this.props.productslug,
    isLoading: false,
    productDetails: '',
    cargoPrices: [],
    servertime: '',
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
};
  componentDidMount() {
    this.setState({ isLoading: true });
    const { cookies } = this.props;
    fetch(this.state.url, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token'),
      },
      credentials: 'include'
     })
      .then((response) => {
        if (!response.ok) {
          return response.json();
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data.message==="") {
          this.setState({ productDetails: data.data,  cargoPrices: data.cargo_prices, servertime:data.server_time.formatted, isLoading: false });
        } else {
          this.setState({ err:true, errorDesc: data.message});
        }
      })
      .catch(err => {
        this.setState({ err:true, errorDesc: err});
        
        //this.myToast("Teknik bir problemimiz var.<br/> Mezat detayı alınamadı oluşturulamadı.<br/> Problem detayı:" + err);
      })
      
     
  }


  render() {

    return (
      !this.state.isLoading &&
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
        <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
        {!this.state.err && this.state.productDetails && <PrevAuctionDetailsChild veri={this.state.productDetails}  cargoPrices={this.state.cargoPrices}  servertime={this.state.servertime} apiurl={this.props.apiurl}></PrevAuctionDetailsChild>}
        {this.state.err && <div className="alert">{this.state.errorDesc}</div>}
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl} />
      </div>
    )
  }
}

export default withCookies(PrevAuctionDetails);