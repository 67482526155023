import React, { Component } from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import UserLeftMenu from './userleftmenu';
import NotAuthorized from '../notauthorized';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

import DateCombo from '../../components/datecombo/datecombo';
import ModalForm from '../../components/modal/modalform';
import UserMobileMenu from './usermobilemenu';
import sanitizeHtml from 'sanitize-html';
import swal from 'sweetalert2';

export class MyQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      isLoading: false,
      isAuthenticated: false,
      isOpen: false,
      isAuctionOpen: false,
      activeIndex: 0,
      activeModal: null,
      err: false,
      auctions:[],
      menuItems: [],
      orderTitle: '',
      orderText: '',
      newQuestionItem: "",
      btnQuestionItemStatus: true,
    }
  }

  
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false, activeModal: null });
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  formatDate = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  numberFormat = (value) => {
    if (value > 0) {
        return Intl.NumberFormat(['ban', 'id']).format(value)
    } else {
        return 0;
    }
    }
  clickHandler(e, index) {
    e.preventDefault();
    this.setState({ isOpen: true, activeModal: index })
  }
  statusCodes = (scode) => {
    switch(scode) {
      case 0:
        return 'İşlem bekliyor';
      case 1:
        return 'Görüldü';
      case 2:
        return 'İnceleniyor';
      case 3:
        return 'Cevaplandi';
      case 4: 
        return 'Çözüldü';
      case 5:
        return 'Çözülemedi';
      default:
        return 'İşlem bekliyor';
    }      
  }
  componentDidMount() {
    this.setState({isLoading:true, isAuthenticated: false});
    const { cookies } = this.props;

    fetch(this.props.apiurl + '/api/myquestions', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        },
        credentials: 'include'
      })
      .then((response) => {
        if(!response.ok) {
            return response.json();
          }
          else{
            return response.json();
          } 
      })
      .then((responseData) => {
        if (responseData.message==='Unauthenticated.') {
          this.setState({ isLoading: false, isAuthenticated:false })
        } else {
          if (responseData) {
            this.setState({ 
               isLoading: false, 
               isAuthenticated:true, 
               auctions: responseData.auctions.data,
               currentPage: responseData.auctions.current_page,
               lastPage: responseData.auctions.last_page,
               menuItems: responseData.support_titles,
               })
          }
        }
       })

      .catch((error) => {
        this.setState({errorDesc: error});
      });
  }
  handleActiveIndex(ind) {
    ind!==this.state.activeIndex ? this.setState({isAuctionOpen: true}) : this.setState({isAuctionOpen: !this.state.isAuctionOpen}) ;
    this.setState({activeIndex: ind});
  }
  

  
  
 
  render() {
    document.title = "Mezatvar - Sorularım"
    if (this.state.isAuthenticated === true) {
      return (
        !this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          

            <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">

              <nav aria-label="breadcrumb" className="main-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Sorularım</li>
                </ol>
              </nav>

              <div className="row gutters-sm">
                <div className="col-md-4 d-none d-md-block">
                  <UserLeftMenu activetab={this.props.activetab}/>
                </div>
                <div className="col-md-8">
                <div className="card">
                <UserMobileMenu activetab={this.props.activetab}/>
                    <div className="card-body tab-content">
                      <div className="tab-pane active" id="profile">
                        <h6>SORULARIM</h6>
                        <hr />
                        {this.state.auctions.length==0 && <div className="justify-content-center text-center">
                                <h1><i className="text-danger fas fa-question"></i></h1>
                                <h2>Herhangi bir sorunuz bulunmamaktadır</h2>
                                </div>}
                        <div id="ProdListContainer01" className="col-md-12 mt-3 mb-3">
                        <div className="col-md-12">
                                {this.state.auctions && this.state.auctions.map((auction) => {
                                  return <div className="card mb-1 auction">
                                          <div className="card-header text-primary font-weight-bold" id={auction.id}   onClick={() => this.handleActiveIndex(auction.id)}>
                                                  <span><a href={"mezat/" + auction.slug} target="_blank">{auction.id} {auction.name} - {auction.title}</a></span><span className="float-right"> {(this.state.activeIndex==auction.id && this.state.isAuctionOpen) ? "-": "+"}</span> 
                                          </div>
                                          {(this.state.activeIndex==auction.id && this.state.isAuctionOpen) && <div id={"description" + auction.id} 
                                              className="collapse show">
                                              <div className="card-body">
                                                  
                                                  <div className="row">
                                                  <div className="col-md-6">
                                                  </div>
                                                  
                                                  </div>
                                                  {auction.auction_question && auction.auction_question.map((questionitem => 
                                                  <>
                                                    <div className="row">
                                                          <div className="col-md-1"></div>
                                                          <div className="col-md-11 alert alert-primary mb-4"> Siz :
                                                          <p>{questionitem.question_body}</p>
                                                          <p className="float-right">{this.formatDate(questionitem.created_at)}</p></div>
                                                        </div>
                                                    {questionitem.auction_answer && questionitem.auction_answer.map((answer => 
                                                      <>
                                                        <div className="row">
                                                          <div className="col-md-1"></div>
                                                          <div className="col-md-11 alert alert-secondary mb-4">{answer.answer_from_name} :
                                                          <p>{answer.answer_body}</p>
                                                          <p className="float-right">{this.formatDate(answer.created_at)}</p></div>
                                                        </div>

                                                      </>
                                                      ))}
                                                  </>
                                                  ))}
                                              </div>
                                              
                                          </div>
                                          }
                                      </div>
                                  })}

                                </div>

                         </div>
                        
                         
                         
                      </div>              
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl}/>
        </div>
      )
    } else {
      return (!this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          <NotAuthorized />
          <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl}/>
        </div>
      )
    }

  }

}


export default withCookies(MyQuestions);