import React, {Component} from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import OrderDetails from './orderdetails';
import NotAuthorized from '../notauthorized';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Swal from 'sweetalert2';

export class Order extends Component {

    state = {
        errorDesc:'',
        isLoading: false,
        isAuthenticated: false,
        err: false,
        orderDetails:'',
      }
      static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };

      componentDidMount() {
        this.getOrderData();
        this.getSalesContractData();
      }

      getOrderData() {
        this.setState({isLoading:true, isAuthenticated: false});
        const { cookies } = this.props;
    
        fetch(this.props.apiurl + '/api/showOrder/' + this.props.orderid , {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + cookies.get('access-token') ,
            },
            credentials: 'include'
          })
          .then((response) => {
            if(!response.ok) {
                throw new Error(response.statusText)              
              }
              else{
                return response.json();
              } 
          })
          .then((responseData) => {
            if (responseData.message==='Unauthenticated.') {
              this.setState({ isLoading: false, isAuthenticated:false })
            } else {
                this.setState({ orderDetails: responseData, isLoading: false, isAuthenticated:true })
            }
           })
    
          .catch((error) => {
            Swal.fire({
              title: "Hata",
              text: error,
              icon: "error",
              timer: 5000,
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: true,
              closeButtonCaption: 'Kapat'
            });
            window.location="/u/siparislerim"
          });
      }
      getSalesContractData() {
        const apiUrl = this.props.apiurl + '/api/contracts/mesafeli-satis-sozlesmesi';
        fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json'            
          },
          credentials: 'include'
        })
          .then(res => res.json())
          .then((data) => {
            data.contract_data && this.setState({ contract_data: data.contract_data})
          })
    
          .catch(err => {
            Swal.fire({
              title: "Hata",
              text: err,
              icon: "error",
              timer: 5000,
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: true,
              closeButtonCaption: 'Kapat'
            });
          })
        
      }
    render() {
        document.title="Mezatvar - Sipariş Detayları"
        
        if (this.state.isAuthenticated===true) {
          return (
            !this.state.isLoading && 
            <div className="App">
              <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
              <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
              <OrderDetails veri={this.state.orderDetails} apiurl={this.props.apiurl} slug={this.props.slug} contract_data={this.state.contract_data}/>
              <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
              <FooterSection apiurl={this.props.apiurl}/>
            </div>
          )
        } else {
          return (!this.state.isLoading &&
            <div className="App">
                <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
                <NotAuthorized/>
                <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl} />
                <FooterSection apiurl={this.props.apiurl}/>
            </div>
          ) 
        }
        
    }
}

export default withCookies(Order);