import React, { Component } from 'react';
import ProductList from '../parts/productlist';

import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import LiveStream from '../parts/livestream';
import SideSearchMenu from '../parts/sidesearchmenu';
import Video from '../parts/video';

export class Search extends Component {
  constructor(props) {
    super(props);
    this.expImg = React.createRef();
    this.state = {
      errorDesc:'',
      url: this.props.apiurl + '/api/live_session/' + this.props.anakategori,
      changed: false,
      live_settings: [],
      bannerSlug: this.buildBannerSlug(),
      criteriaLink: this.buildCriteriaLink(),
      categories: [],
    }
    this.listenToChannels = this.listenToChannels.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
  }

  componentDidMount() {
    this.listenToChannels();
    this.getSitelive_settingsData();

  }

  listenToChannels = () => {
    window.Echo.channel('livesession')
      .listen('LiveSessionStarted', (e) => {
        this.getSitelive_settingsData();
      })
      .listen('LiveSessionStopped', (e) => {
        this.getSitelive_settingsData();
      })
  }
  handleCategoryChange(cat) {
    this.setState({ categories: cat });
  }
  async getSitelive_settingsData() {
    fetch(this.state.url, {method: 'GET', credentials: 'include'})
      .then(res => res.json())
      .then((data) => {
        this.setState({ live_settings: data, changed: true })
      })

      .catch(err => {
        this.setState({errorDesc: err});

        //this.myToast("Teknik bir problemimiz var.<br/> Menü oluşturulamadı.<br/> Problem detayı:" + err);
      })
  }

  buildCriteriaLink() {
    let criteriaLink = this.props.criteriaLink;
    let isFirstQuery = true;
    if (this.props.anakategori) {
      criteriaLink = criteriaLink + '?category_1=' + this.props.anakategori;
      isFirstQuery = false;
    }
    if (this.props.kategori) {
      criteriaLink = criteriaLink + '&category_2=' + this.props.kategori;
      isFirstQuery = false;
    }
    if (this.props.altkategori) {
      criteriaLink = criteriaLink + '&category_3' + this.props.altkategori;
      isFirstQuery = false;
    }

    if (this.props.q) {
      criteriaLink = isFirstQuery==true ? criteriaLink + '?q=' + this.props.q : criteriaLink + '&q=' + this.props.q
    }

    return criteriaLink;
  }

  buildBannerSlug() {
    let bannerSlug = this.props.slug;
    if (this.props.anakategori) {
      bannerSlug = bannerSlug + '/' + this.props.anakategori;
    }
    if (this.props.kategori) {
      bannerSlug = bannerSlug + '/' + this.props.kategori;
    }
    if (this.props.altkategori) {
      bannerSlug = bannerSlug + '/' + this.props.altkategori;
    }

    return bannerSlug;
  }


  render() {
    document.title = "Mezatvar - Aradığınız mezatlar"

    return (
      <div className="App">
        <Banner placement='0' slug={this.state.bannerSlug} showModal={true} apiurl={this.props.apiurl}/>
        <Header q={this.props.q} slug={this.props.slug} apiurl={this.props.apiurl} />
        <div id="LiveStreamContainer" className="container-fluid bg-light shadow-lg mb-2">
              {this.state.changed && this.state.live_settings && this.state.live_settings.map(setting =>
                setting.live_active ? <LiveStream liveURL={setting.live_broadcast_url} live_session_id={setting.id} live_session_start={setting.live_session_started_at} apiurl={this.props.apiurl}></LiveStream> :
                  null
              )}
            </div>
        
        <div className="container mb-2 mt-2 rounded">
        
          <div className="row">
          <div className="card col-md-3">
            <SideSearchMenu apiurl={this.props.apiurl} anakategori={this.props.anakategori} kategori={this.props.kategori} q={this.props.q} criteria={this.state.criteriaLink} categories={this.state.categories}/>
            </div>
            <div className="col-md-9">
              <div className="row mt-2 mb-2 pl-3 pb-1 pt-2"><h4>{this.props.title}</h4></div>
              <div className="row mb-2">
              <div className="col-12"><Video apiurl={this.props.apiurl} slug={this.state.bannerSlug}/></div>
              </div>
              <Banner placement='1' slug={this.state.bannerSlug} showModal={true} apiurl={this.props.apiurl}/>
              <ProductList key="pl1" handleCategoryChange={this.handleCategoryChange} className="col-md-6 col-lg-4 col-xl-4 p-3" productlistheader='' apiurl={this.props.apiurl} criteria={this.state.criteriaLink} q={this.props.q}/>
            </div>
          </div>

        </div>
        <Banner placement='2' slug={this.state.bannerSlug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl}/>
      </div>

    )
  }
}

export default Search;